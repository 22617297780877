// Containers
import CommonSettingsContainer from 'client/containers/dashboard/CommonSettings/containers/CommonSettingsContainer'

// Routes
import CommonSettingsMenuRoutes from './routes/CommonSettingsMenuRoutes'


const CommonSettingsRoutes = [
  {
    path: '/:username/common-settings',
    component: CommonSettingsContainer,
    routes: [
      ...CommonSettingsMenuRoutes
    ]
  }
]

export default CommonSettingsRoutes
