import React, { Fragment } from 'react'

// Components Global
import Select from '../../../../../../components/Inputs/Select'
import InputDatePicker from '../../../../../../components/Inputs/InputDatePicker'
import DatePickerBetween from '../../../../../../components/Inputs/DatePickerBetweenV2'
import InputNumeral from '../../../../../../components/Inputs/InputNumeral'
import Textarea from '../../../../../../components/Inputs/Textarea'
import TextInput from '../../../../../../components/Inputs/TextInput'
import InputDisabled from '../../../../../../components/Inputs/InputDisabled'
import InputDropdownMultiple from '../../../../../../components/Inputs/InputDropdownMultiple'
import AvatarIcon from '../../../../../../components/AvatarIcon'

// Components Rubber Customers
import RubberCustomerInputSelect from '../../../RubberCustomerBuyers/components/RubberCustomerInputSelect'


// Redux
import { fetchPurchases } from '../../../../../../redux/Purchases/PurchasesActions'
import { fetchSales } from '../../../../../../redux/Sales/SalesActions'
import { fetchInventories } from '../../../../../../redux/Inventories/InventoriesActions'

// Utils
import { getDate } from '../../../../../../util/getDateTime'
import { getNumeral } from '../../../../../../util/getNumeral'
import { getRSSTypesDefault, getRSTypesDefault, getRubberTypeValue } from '../../../../../../redux/RubberSettings/RubberSettingsValue'




export const RubberTypeSelect = (props) => {
  let options = [
    { value: 'field-latex', name: 'น้ำยางสด', productType: 'field-latex' },

    { value: 'rubber-sheet', name: 'ยางแผ่นดิบ', productType: 'rubber-sheet' },
    { value: 'ribbed-smoked-sheet', name: 'ยางแผ่นรมควัน', productType: 'rubber-sheet' },

    { value: 'rubber-sheet', name: 'ทำยางแผ่นดิบ', productType: 'rubber-sheet-make' },
    { value: 'ribbed-smoked-sheet', name: 'ทำยางแผ่นรมควัน', productType: 'rubber-sheet-make' }
  ]

  options = options.filter(o => o.productType === props.productType)

  const getValue = (rubberType) => {
    if (rubberType) {
      const value = options.filter(o => o.value === rubberType)[0]

      if (value) {
        return value.name
      }
    }
  }

  return (
    <Fragment>
      { !props.forContract && !props.fixed &&
        <Fragment>
          { !props.disabled ?
            <Fragment>
              { options.length > 1 ?
                <Select
                  id="rubberType"
                  label="ประเภทยาง"
                  placeholder="-- เลือกประเภท"
                  value={props.value}
                  default
                  required
                  inputClassName="height-35 font-0-8"
                  labelClassName="font-0-8"
                  options={options}
                  onChange={props.onChange}
                />
              :
                <TitleShow
                  value={options[0] && options[0].name}
                  type="text"
                  label="ประเภทยาง"
                  textAlign="left"
                />
              }
            </Fragment>
          :
            <TitleShow
              value={getValue(props.value)}
              type="text"
              label="ประเภทยาง"
              textAlign="left"
            />
          }
        </Fragment>
      }

      { props.forContract && !props.fixed &&
        <Fragment>
          { props.event === 'create' &&
            <TitleShow
              value={getValue(props.forContract.rubberType)}
              type="text"
              label="ประเภทยาง"
              textAlign="left"
            />
          }

          { props.event === 'update' &&
            <TitleShow
              value={getValue(props.value)}
              type="text"
              label="ประเภทยาง"
              textAlign="left"
            />
          }
        </Fragment>
      }

      { props.fixed &&
        <TitleShow
          value={getValue(props.fixed.rubberType)}
          type="text"
          label="ประเภทยาง"
          textAlign="left"
        />
      }
    </Fragment>
  )
}

export const RubberType2Select = (props) => {
  const { rubberType, rubberType2 } = props
  const RSTypes = getRSTypesDefault()
  const RSSTypes = getRSSTypesDefault()

  const attr = {
    id: 'rubberType2',
    placeholder: '-- เลือกประเภท',
    value: rubberType2,
    default: true,
    required: true,
    inputClassName: 'height-35 font-0-8',
    labelClassName: 'font-0-8',
    onChange: props.onChange,
    label: 'ประเภทยาง (รอง)'
  }

  const getValue = (types) => {
    if (rubberType2) {
      const value = types.filter(o => o.value === rubberType2)[0]

      if (value) {
        return value.name
      }
    }
  }

  return (
    <Fragment>
      { props.saleType === 'sell-on-cash' ?
        <Fragment>
          { (!rubberType || rubberType === 'field-latex') ?
            <TitleShow
              value=""
              type="text"
              label="ประเภทยาง (รอง)"
              textAlign="left"
            />
          :
            <Fragment>
              { rubberType === 'rubber-sheet' &&
                <Select {...attr} options={RSTypes} />
              }

              { rubberType === 'ribbed-smoked-sheet' &&
                <Select {...attr} options={RSSTypes} />
              }
            </Fragment>
          }
        </Fragment>
      :
        <Fragment>
          { !rubberType ?
            <TitleShow
              value=""
              type="text"
              label="ประเภทยาง (รอง)"
              textAlign="left"
            />
          :
            <Fragment>
              { rubberType === 'rubber-sheet' && rubberType2 &&
                <TitleShow
                  value={getValue(RSTypes)}
                  type="text"
                  label="ประเภทยาง (รอง)"
                  textAlign="left"
                />
              }

              { rubberType === 'ribbed-smoked-sheet' && rubberType2 &&
                <TitleShow
                  value={getValue(RSSTypes)}
                  type="text"
                  label="ประเภทยาง (รอง)"
                  textAlign="left"
                />
              }
            </Fragment>
          }
        </Fragment>
      }
    </Fragment>
  )
}


export const SaleSelectDates = (props) => {
  const id = `dates`
  const { business } = props
  let where = `businesses_id=${business._id},soldOut=false`

  const fetchAction = {
    action: fetchPurchases,
    actionQuery: {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id
    },
    id: business._id,
    where,
    countWhere: where,
    groupBy: JSON.stringify({
      _id: "$date",
      date: { "$first": "$date" }
    }),
    sort: 'date',
    limit: 100
  }

  const item = ({ data, checked }) => {
    const purchase = data

    return (
      <div>
        { purchase &&
          <div className="border-bottom-efefef pd-10">
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 text-left width-50">
                    <div className="width-40 height-40 line-height-40">
                      <AvatarIcon
                        bgColor="#efefef"
                        circle
                        iconClassName="font-1-5' color-333"
                        icon="event"
                      />
                    </div>
                  </td>
                  <td className="pd-5 line-height-0">
                    <div className="font-0-9">{ getDate(purchase.date, 'DD/MM/YYYY', 'th', true) }</div>
                    <div className="font-0-6 mg-top-20">
                      วันที่ซื้อยาง
                    </div>
                  </td>
                  <td className="pd-0 text-right width-30">
                    { checked &&
                      <i className="material-icons font-1-8 color-success">check_circle_outline</i>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }

  const getChipValue = (item) => {
    if (item) {
      return {
        value: getDate(item.date, 'DD/MM/YY', 'th', true),
      }
    }
  }

  return (
    <InputDropdownMultiple
      fetchAction={fetchAction}
      item={item}
      storeName="purchases"
      items={props.stock && props.stock.dates}
      onChange={props.onChange}
      label="วันที่ซื้อยาง"
      id={id}
      placeholder="วันที่ซื้อยาง"
      type="text"
      getChipValue={getChipValue}
      inputClassName="min-height-35 font-0-8"
      labelClassName="font-0-8"
      chipSize={25}
      required
      disabled={props.event !== 'create'}
      notSave={props.notSave}
      chipClassName="font-0-8"
      hiddenSearch
    />
  )
}


export const RubberInventorySelectDates = (props) => {
  const id = `dates`
  const { business, productType, inventoryType, rubberType, type, customer } = props
  let where = `businesses_id=${business._id},type=${inventoryType},netWeight=!0`

  if (inventoryType === 'deposit' && customer) {
    where = `${where},customers_id=${customer._id}`
  }

  if (productType === 'rubber-sheet-make') {
    where = `${where},rubberType=field-latex`

  } else if (productType === 'rubber-sheet') {
    where = `${where},rubberType=${rubberType}`

  } else if (productType === 'field-latex') {
    where = `${where},rubberType=${rubberType}`
  }

  const fetchAction = {
    action: fetchInventories,
    actionQuery: {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id
    },
    id: business._id,
    where,
    countWhere: where,
    sort: 'date',
    limit: 100
  }

  let label


  if (type.code === 'field-latex' || type.code === 'rubber-sheet-make') {
    label = 'วันที่ซื้อน้ำยาง'
  } else if (type.code === 'rubber-sheet') {
    if (inventoryType === 'deposit') {
      label = `วันที่ฝากยางแผ่น`
    } else {
      label = `วันที่ทำยางแผ่น`
    }
  }

  if (rubberType === 'rubber-sheet') {
    label = `${label}ดิบ`
  } else if (rubberType === 'ribbed-smoked-sheet') {
    label = `${label}รมควัน`
  }

  const item = ({ data, checked }) => {
    const inventory = data


    let eventType

    if (inventory) {
      const rubberType = getRubberTypeValue(inventory.rubberType)

      if (inventory.eventType === 'purchase') {
        eventType = `วันที่ซื้อ${rubberType.name}`
      } else if (inventory.eventType === 'sale-make') {
        eventType = `วันที่ทำ${rubberType.name}`
      } else if (inventory.eventType === 'deposit') {
        eventType = `วันฝาก${rubberType.name}`
      }
    }

    return (
      <div>
        { inventory &&
          <div className="border-bottom-efefef pd-10">
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 text-left width-50">
                    <div className="width-40 height-40 line-height-40">
                      <AvatarIcon
                        bgColor="#efefef"
                        circle
                        iconClassName="font-1-5' color-333"
                        icon="event"
                      />
                    </div>
                  </td>
                  <td className="pd-5 line-height-0">
                    <div className="font-0-9">{ getDate(inventory.date, 'DD/MM/YYYY', 'th', true) }</div>
                    <div className="font-0-6 mg-top-20">
                      { eventType }
                    </div>
                  </td>
                  <td className="pd-0 text-right width-30">
                    { checked &&
                      <i className="material-icons font-1-8 color-success">check_circle_outline</i>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }

  const getChipValue = (item) => {
    if (item) {
      return {
        value: getDate(item.date, 'DD/MM/YY', 'th', true),
      }
    }
  }

  const disabled = (props.event !== 'create' || props.disabled)

  return (
    <Fragment>
      { props.rubberType ?
        <InputDropdownMultiple
          fetchAction={fetchAction}
          item={item}
          storeName="inventories"
          items={props.cost && props.cost.inventories}
          onChange={props.onChange}
          label={label}
          id={id}
          placeholder={label}
          type="text"
          getChipValue={getChipValue}
          inputClassName="min-height-35 font-0-8"
          labelClassName="font-0-8"
          chipSize={25}
          required
          disabled={disabled}
          notSave={props.notSave}
          chipClassName="font-0-8"
          hiddenSearch
        />
      :
        <InputDisabled
          label={label}
          required
          value="*** ยังไม่เลือกประเภทยาง"
          inputClassName="color-000 font-0-8 height-30 line-height-30 pd-right-10"
          labelClassName="color-000 mg-bottom-4 font-0-8"
        />
      }
    </Fragment>
  )
}


export const TitleShow = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(true)

  let value = props.value

  if (value) {
    if (props.type === 'date') {
      value = getDate(value, 'DD/MM/YYYY', 'th', true)
    } else if (props.type === 'date-short') {
      value = getDate(value, 'DD/MM/YY', 'th', true)
    } else if (props.type === 'number') {
      value = getNumeral(value, 'auto')
    }
  }

  return (
    <TextInput
      id="title"
      label={props.label}
      inputClassName={`font-0-8 ${props.inputClassName || ''} ${inputClassName} pd-left-10`}
      labelClassName={labelClassName}
      inputStyle={{...inputStyle, textAlign: props.textAlign }}
      value={value}
      disabled
      notSave
    />
  )
}

export const InputNumber = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id="number"
      label={props.label}
      placeholder={props.label}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value || 0}
      disabled={props.disabled}
      required={props.required}
      maxValue={props.maxValue}
      decimal={2}
      notSave
    />
  )
}

export const SaleIncomeTitle = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <TextInput
      id="title"
      label="รายละเอียด"
      placeholder="รายละเอียด"
      inputClassName={`font-0-8 text-left ${inputClassName} pd-left-10`}
      labelClassName={labelClassName}
      inputStyle={{...inputStyle, textAlign: 'left' }}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      required={props.required}
      notSave
    />
  )
}

export const SaleIncomePricePerUnit = (props) => {
  const { digit } = props.type.buyWeightDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id={`pricePerUnit`}
      label="ราคา/กก."
      placeholder="ราคา/กก."
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      decimal={2}
      notSave
    />
  )
}

export const SaleIncomePrice = (props) => {
  const { digit } = props.type.buyWeightDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id={`price`}
      label="จำนวนเงิน (-หัก/+เพิ่ม)"
      placeholder="จำนวนเงิน"
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      decimal={digit}
      notSave
    />
  )
}

export const getDisabledClassName = (disabled) => {
  let inputClassName, labelClassName, inputStyle

  if (disabled) {
    inputClassName = "color-000 bg-eee height-30 line-height-30 text-right pd-right-10"
    labelClassName = "color-000 mg-bottom-4 font-0-8"
    inputStyle ={
      border: '1px dotted rgba(0, 0, 0, 0.26)',
      boxSizing: 'border-box'
    }

  } else {
    inputClassName="text-center height-35 line-height-35 font-0-8"
    labelClassName="font-0-8"
  }

  return { inputClassName, labelClassName, inputStyle }
}


export const SaleDateSearchPurchases = (props) => {
  return (
    <DatePickerBetween
      id="date-search"
      onChange={props.onChange}
      bbYear
      dateFormat="D MMM YYYY"
      timeFormat="เวลา H:mm น."
      inputClassName="font-0-8 height-35 line-height-35"
      labelClassName="font-0-8"
      required
      start={{
        id: 'stock.startDate',
        label: 'วันที่ซื้อยาง เริ่มต้น',
        value: props.startDate,
        col: props.col_1,
        disabled: props.startDate,
        notSave: true
      }}
      end={{
        id: 'stock.endDate',
        label: 'วันที่ซื้อยาง สิ้นสุด',
        value: props.endDate,
        col: props.col_2,
        disabled: props.startDate,
        notSave: true
      }}
    />
  )
}

export const SaleDate = (props) => {
  return (
    <InputDatePicker
      id="date"
      required
      label={props.label || 'วันที่ขาย'}
      dateNow
      value={props.value}
      onChange={props.onChange}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      bbYear
    />
  )
}


export const SaleCustomerBuyer = (props) => {
  const type = props.type.defaultValue
  let where = 'status=open,type=buyer'

  if (type.code === 'rubber-sheet') {
    where = `${where},rubberSheet=true`
  } else if (type.code === 'field-latex') {
    where = `${where},fieldLatex=true`
  }

  return (
    <RubberCustomerInputSelect
      {...props}
      label="โรงงาน/ผู้ซื้อ"
      onChange={props.onChange}
      customer={props.customer}
      where={where}
      required={props.required}
      inputClassName="height-35 line-height-35 font-0-8"
      labelClassName="font-0-8"
      notSave
      disabled={props.disabled}
    />
  )
}

export const SaleNote = (props) => {
  return (
    <Textarea
      id="note"
      label="หมายเหตุ"
      placeholder="หมายเหตุ"
      inputClassName="height-35 line-height-35 font-0-8"
      inputClassNameModify="min-height-35 pd-top-0 pd-bottom-0"
      labelClassName="font-0-8"
      value={props.value}
      onChange={props.onChange}
    />
  )
}


export const SaleNetWeight = (props) => {
  const { digit } = props.type.saleWeightDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label = props.label


  if (!label) {
    if (props.type.code === "rubber-sheet") {
      label = "นน.ยางแผ่น รวม"
    } else {
      label = "นน.น้ำยางสด รวม"
    }
  }

  return (
    <InputNumeral
      id={`netWeight`}
      label={label}
      placeholder={label}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.element === 'sale'}
      notSave
      decimal={digit}
    />
  )
}

export const SaleNetWeightMake = (props) => {
  const { digit } = props.type.saleWeightDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id={`saleNetWeightMake`}
      label={props.label || "นน.ยางแผ่นที่ทำได้"}
      placeholder={props.label || "นน.ยางแผ่นที่ทำได้"}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      notSave
      decimal={digit}
    />
  )
}

export const SaleLatexPercent = (props) => {
  const { digit } = props.type.salePercentDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label

  if (props.type.code === "rubber-sheet") {
    label = "% ยางแผ่น"
  } else {
    label = "% น้ำยางสด"
  }

  if (props.element === 'stock') {
    label = `${label} (เฉลี่ย)`
  }



  return (
    <InputNumeral
      id={`latexPercent`}
      label={label}
      placeholder={label}
      onChange={props.onChange}
      value={props.value}
      maxValue="100"
      required={props.element === 'sale'}
      notSave
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled={props.disabled}
      decimal={digit}
    />
  )
}

export const SaleLatexRSPercent = (props) => {
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label = "% ยางแผ่น"

  return (
    <InputNumeral
      id={`latexRSPercent`}
      label={label}
      placeholder={label}
      onChange={props.onChange}
      value={props.value}
      maxValue="100"
      required
      notSave
      inputClassName={`${inputClassName} font-0-8`}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled={props.disabled}
      decimal={2}
    />
  )
}

export const SaleLatexPercentBuyer = (props) => {
  const { digit } = props.type.salePercentDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label = '% น้ำยาง (วัดเอง)'

  return (
    <InputNumeral
      id={`latexPercentBuyer`}
      label={label}
      placeholder={label}
      onChange={props.onChange}
      value={props.value}
      maxValue="100"
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled={props.disabled}
      notSave
      decimal={digit}
    />
  )
}

export const SaleDryLatexWeight = (props) => {
  const { digit } = props.type.saleDryLatexWeightDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  return (
    <InputNumeral
      id={`dryLatexWeight`}
      label="นน.ยางแห้ง รวม"
      placeholder="นน.ยางแห้ง รวม"
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled={props.disabled}
      value={props.value}
      notSave
      decimal={digit}
      onChange={props.onChange}
    />
  )
}



export const SalePrice = (props) => {
  const { digit } = props.type.salePriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label = 'ราคา/กก.'

  if (props.element === 'stock') {
    label = `${label} (เฉลี่ย)`
  }

  return (
    <InputNumeral
      id={`price`}
      label={label}
      placeholder={label}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled={props.disabled}
      decimal={digit}
      value={props.value}
      onChange={props.onChange}
      notSave
      required={!props.disabled}
    />
  )
}

export const SaleAddPrice = (props) => {
  const { digit } = props.type.salePriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label = '-หัก/+เพิ่ม ราคา/กก.'

  return (
    <InputNumeral
      id={`addPrice`}
      label={label}
      placeholder={label}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      decimal={digit}
      value={props.value}
      notSave
      onChange={props.onChange}
      disabled={props.disabled}
    />
  )
}


export const SaleNetPrice = (props) => {
  const { digit } = props.type.salePriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(props.disabled)

  let label = 'ราคา/กก. สุทธิ'

  if (props.element === 'stock') {
    label = `${label} (เฉลี่ย)`
  }

  return (
    <InputNumeral
      id={`netPrice`}
      label={label}
      placeholder={label}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled={props.disabled}
      decimal={digit}
      value={props.value}
      notSave
      onChange={props.onChange}
    />
  )
}


export const SaleAddTotalPrice = (props) => {
  const { digit } = props.type.saleTotalPriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(false)

  let label = '-หัก/+เพิ่ม ราคารวม'

  return (
    <InputNumeral
      id={`addTotalPrice`}
      label={label}
      placeholder={label}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      decimal={digit}
      value={props.value}
      notSave
      onChange={props.onChange}
    />
  )
}

export const SaleTotalPrice = (props) => {
  const { digit } = props.type.saleTotalPriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(true)

  return (
    <InputNumeral
      id={`totalPrice`}
      label="รวมเป็นเงินสุทธิ"
      onChange={props.onChange}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      inputStyle={inputStyle}
      disabled
      decimal={digit}
      value={props.value || 0}
      notSave
      valueForSplace="0"
    />
  )
}

export const SaleTotalIncomes = (props) => {
  const { digit } = props.type.saleTotalPriceDCM
  const { inputClassName, labelClassName, inputStyle } = getDisabledClassName(true)

  return (
    <InputNumeral
      id={`totalIncomes`}
      label="รวม รายได้/ค่าใช้จ่าย อื่นๆ"
      inputClassName={`text-right ${inputClassName}`}
      labelClassName={`${labelClassName}`}
      inputStyle={inputStyle}
      disabled
      decimal={digit}
      value={props.value || 0}
      valueForSplace="0"
      notSave
    />
  )
}

export const SaleTotalPriceAll = (props) => {
  const { digit } = props.type.saleTotalPriceDCM

  return (
    <InputNumeral
      id={`sale.totalPriceAll`}
      label="รวมเป็นเงินทั้งหมด"
      labelClassName="mg-bottom-4 color-000"
      inputClassName="color-fff bg-000 height-45 line-height-45 text-right pd-right-10 font-1-8"
      inputStyle={{
        border: '1px dotted rgba(0, 0, 0, 0.26)',
        boxSizing: 'border-box'
      }}
      disabled
      decimal={digit}
      value={props.value || 0}
      valueForSplace="0"
      notSave
    />
  )
}
