import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

// Utils
import getFormValue from 'utils/getFormValue'

//components
import { Box, Row, ButtonClose } from 'components/UserInterface'
import ModalDefault from 'components/modal/ModalDefault'
import FormValidation from 'components/FormValidation'
import BtnSubmit from 'components/button/BtnSubmit'

// Component Global
import { Email } from './components/AuthForms'

// Import Actions
import { forgotPassword } from 'core/redux/Auth/AuthActions'

// Import file
import forgotPasswordSetting from './imgs/forgot-password-setting.png'
import forgotPasswordSuccess from './imgs/forgot-password-success.png'


class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: false
    }
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    if (params) {
      this.props.dispatch(forgotPassword({
        body: params,
        loadId: formName,
        errorAlert: true,
        toastMsg: 'ส่งลิงก์กำหนดรหัสผ่านของคุณสำเร็จ'
      })).then(res => {
        if (res.success && res.data) {
          this.setState({ email: res.data.email })
        } else {
          this.setState({ email: false })
        }
      })
    }
  }

  render() {
    return (
      <div>
        <Helmet title="กำหนดรหัสผ่านของคุณใหม่" />

        <ModalDefault showOnly={true} bgOverlay="#7cb342" width="450px">
          <Box padding="25px" background="#fff">
            <ButtonClose to={`/`} />

            { !this.state.email ?
              <div>
                <div className="center mg-bottom-35">
                  <img src={forgotPasswordSetting} />
                  <b className="dp-block mg-top-10">กำหนดรหัสผ่านของคุณใหม่</b>
                  <p>ส่งลิงก์มาให้ฉันทางอีเมลเพื่อกำหนดรหัสผ่านของฉันใหม่</p>
                </div>

                <FormValidation ref="forgotPassword" name="forgotPassword" submit={(e) => this.handleSubmit(e, 'forgotPassword')} >
                  <Row>
                    <Email />
                  </Row>
                  <Row align="center">

                    <BtnSubmit
                      loadingId="forgotPassword"
                      icon="lock_open"
                      iconAlign="left"
                      text="ส่งลิงก์ไปยังอีเมลของฉัน"
                      className="waves-effect waves-light btn light-green darken-1 width-full-xs"
                      btnDisabled
                    />

                  </Row>
                </FormValidation>
              </div>
            :
              <div>
                <div className="center mg-bottom-25">
                  <img src={forgotPasswordSuccess} />
                  <b className="dp-block mg-top-10">ส่งลิงก์กำหนดรหัสผ่านของคุณสำเร็จ</b>
                  <p>เราได้ส่งลิงก์การกำหนดรหัสผ่านใหม่ของคุณไปยังอีเมลด้านล่างนี้</p>
                </div>

                <Row align="center">
                  <b style={{ border: '1px solid #7cb342', width: '100%', display: 'inline-block', borderRadius: '30px', padding: '8px 20px'}}>
                    { this.state.email }
                  </b>
                </Row>
              </div>
            }

          </Box>
        </ModalDefault>
      </div>
    )
  }
}


export default connect()(ForgotPassword)
