import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberContractsMenuContainer from 'client/containers/dashboard/SystemRubber/RubberContracts/containers/RubberContractsMenuContainer'

// Components
const RubberContractsListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberContracts/containers/RubberContractsListContainer'))
const RubberContractManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberContracts/pages/RubberContractManagePage'))

// Types
const rubberType = ":rubberType(all|field-latex|rubber-sheet|ribbed-smoked-sheet)"

const RubberContractsMenuRoutes = [
  {
    path: `/:username/rubber-contracts/${rubberType}`,
    component: RubberContractsMenuContainer,
    routes: [
      {
        path: `/:username/rubber-contracts/${rubberType}/:contracts_id`,
        component: suspenseComponent(RubberContractsListContainer),
        routes: [
          {
            path: `/:username/rubber-contracts/${rubberType}/:contracts_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberContractManagePage)
          },
        ]
      }
    ]
  },
]

export default RubberContractsMenuRoutes
