import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import CustomerAffiliatesMenuContainer from 'client/containers/dashboard/CustomerAffiliates/containers/CustomerAffiliatesMenuContainer'

// Components
const CustomerAffiliatesListContainer = lazy(() => import('client/containers/dashboard/CustomerAffiliates/containers/CustomerAffiliatesListContainer'))
const CustomerAffiliateManagePage = lazy(() => import('client/containers/dashboard/CustomerAffiliates/pages/CustomerAffiliateManagePage'))


const status = ':status(all|approved|not-approved|open|close|trash|vip)'

const CustomerAffiliatesMenuRoutes = [
  {
    path: '/:username/customer-affiliates',
    component: CustomerAffiliatesMenuContainer,
    routes: [
      {
        path: `/:username/customer-affiliates/${status}/:customers_id`,
        component: suspenseComponent(CustomerAffiliatesListContainer),
        routes: [
          {
            path: `/:username/customer-affiliates/${status}/:customers_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(CustomerAffiliateManagePage),
          }
        ]
      },
    ]
  }
]

export default CustomerAffiliatesMenuRoutes
