// Containers
import CustomersContainer from 'client/containers/dashboard/Customers/containers/CustomersContainer'
import CustomerContainer from 'client/containers/dashboard/Customers/containers/CustomerContainer'

// Routes
import CustomersMenuRoutes from './routes/CustomersMenuRoutes'
import CustomerUpdateRoutes from './routes/CustomerUpdateRoutes'


const CustomersRoutes = [
  {
    path: '/:username/customers',
    component: CustomersContainer,
    routes: [
      {
        path: '/:username/customers/advanced/:customers_id',
        component: CustomerContainer,
        auth: { reqAuthBiz: true, reqExpDate: true },
        routes: [
          ...CustomerUpdateRoutes
        ]
      },
      ...CustomersMenuRoutes
    ]
  }
]

export default CustomersRoutes
