import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberDepositsMenuContainer from 'client/containers/dashboard/SystemRubber/RubberDeposits/containers/RubberDepositsMenuContainer'



// List by user
const RubberInventoryCustomersListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberDeposits/containers/RubberInventoryCustomersListContainer'))
const RubberInventoryCustomerManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberDeposits/pages/RubberInventoryCustomerManagePage'))

// List Deposits
const RubberDepositsListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberDeposits/containers/RubberDepositsListContainer'))
const RubberDepositManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberDeposits/pages/RubberDepositManagePage'))

// List Sales
const RubberSalesListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberSales/containers/RubberSalesListContainer'))
const RubberSaleManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberSales/pages/RubberSaleManagePage'))


// Types
const productType = ":productType(rubber-sheet)"
const rubberType = ":rubberType(all|rubber-sheet|ribbed-smoked-sheet)"
const customers_rubber_type = ":customers_rubber_type(rubber-sheet|ribbed-smoked-sheet)"
const saleType = ":saleType(deposit)"

const RubberDepositsMenuRoutes = [
  {
    path: `/:username/rubber-deposits`,
    component: RubberDepositsMenuContainer,
    routes: [
      {
        path: `/:username/rubber-deposits/${rubberType}/:deposits_id`,
        component: suspenseComponent(RubberDepositsListContainer),
        routes: [
          {
            path: `/:username/rubber-deposits/${rubberType}/:deposits_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberDepositManagePage)
          },
        ]
      },

      {
        path: `/:username/rubber-deposits/inventories/${rubberType}/:customers_id/${customers_rubber_type}?`,
        component: suspenseComponent(RubberInventoryCustomersListContainer),
        routes: [
          {
            path: `/:username/rubber-deposits/inventories/${rubberType}/:customers_id/${customers_rubber_type}?`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberInventoryCustomerManagePage)
          },
        ]
      },

      /* for sale */
      {
        path: `/:username/rubber-deposits/sales/${productType}/${rubberType}/${saleType}/sales/:sales_id`,
        component: suspenseComponent(RubberSalesListContainer),
        params: { saleType: 'deposit' },
        routes: [
          {
            path: `/:username/rubber-deposits/sales/${productType}/${rubberType}/${saleType}/sales/:sales_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberSaleManagePage)
          },
        ]
      }
    ]
  },
]

export default RubberDepositsMenuRoutes
