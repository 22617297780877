import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import SettingsMenuContainer from 'client/containers/dashboard/Settings/containers/SettingsMenuContainer'


// Components Common
const Descriptions = lazy(() => import('client/containers/dashboard/CommonSettings/pages/Descriptions'))
const Company = lazy(() => import('client/containers/dashboard/CommonSettings/pages/Company'))
const Tax = lazy(() => import('client/containers/dashboard/CommonSettings/pages/Tax'))
const Pictures = lazy(() => import('client/containers/dashboard/CommonSettings/pages/Pictures'))

const PaymentMethodsPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/PaymentsPage'))
const BankAccountsList = lazy(() => import('client/containers/dashboard/Settings/pages/Business/BankAccounts/BankAccountsList'))

const RunningNumbers = lazy(() => import('client/containers/dashboard/Settings/pages/Business/RunningNumbers'))
const Logo = lazy(() => import('client/containers/dashboard/Settings/pages/Business/Logo'))
const Stamp = lazy(() => import('client/containers/dashboard/Settings/pages/Business/Stamp'))
const ElectronicSignature = lazy(() => import('client/containers/dashboard/Settings/pages/Business/ElectronicSignature'))

const BillShortPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/BillShortPage'))
const BillFullPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/BillFullPage'))

const ShippingFeesPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/ShippingFeesPage'))

const SystemsAllPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/SystemsAllPage'))
const SystemsPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/SystemsPage'))

const AlbumsListPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/Albums/AlbumsListPage'))

const NotFound = lazy(() => import('client/common/Pages/NotFound'))

const NotificationsListPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/NotificationsListPage'))


const MessagingEmail = lazy(() => import('client/containers/dashboard/Settings/pages/Business/MessagingEmail'))
const MessagingLineNotify = lazy(() => import('client/containers/dashboard/Settings/pages/Business/MessagingLineNotify'))
const MessagingFacebook = lazy(() => import('client/containers/dashboard/Settings/pages/Business/MessagingFacebook'))

const CustomerGroupsListPage = lazy(() => import('client/containers/dashboard/Settings/pages/Business/CustomerGroupsListPage'))

const SettingsMenuRoutes = [
  {
    path: '/:username/settings',
    component: SettingsMenuContainer,
    routes: [
      {
        path: '/:username/settings',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Descriptions)
      },
      {
        path: '/:username/settings/company',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Company)
      },
      {
        path: '/:username/settings/tax',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Tax)
      },
      {
        path: '/:username/settings/pictures',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Pictures)
      },



      {
        path: '/:username/settings/messaging/email',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(MessagingEmail)
      },
      {
        path: '/:username/settings/messaging/line-notify',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(MessagingLineNotify)
      },
      {
        path: '/:username/settings/messaging/facebook',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(MessagingFacebook)
      },

      {
        path: '/:username/settings/payment-methods',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(PaymentMethodsPage)
      },
      {
        path: '/:username/settings/bank-accounts',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(BankAccountsList)
      },
      {
        path: '/:username/settings/running-numbers',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(RunningNumbers)
      },
      {
        path: '/:username/settings/logo',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Logo)
      },
      {
        path: '/:username/settings/stamp',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(Stamp)
      },
      {
        path: '/:username/settings/electronic-signature',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ElectronicSignature)
      },
      {
        path: '/:username/settings/bill-short',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(BillShortPage)
      },
      {
        path: '/:username/settings/bill-full',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(BillFullPage)
      },
      {
        path: '/:username/settings/shipping-fees',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ShippingFeesPage)
      },
      {
        path: '/:username/settings/systems',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SystemsAllPage)
      },

      {
        path: `/:username/settings/notifications/:typeNotification(email|facebook|line-notify)`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(NotificationsListPage)
      },
      {
        path: `/:username/settings/groups/customer`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(CustomerGroupsListPage)
      },
      {
        path: '/:username/settings/system/:system_name(products|folders|customers|articles|attributes|orders|bills)',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SystemsPage)
      },
      {
        path: '/:username/settings/albums/:system_name(products|product-items|folders|customers|articles|attributes)',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(AlbumsListPage)
      },
      {
        component: suspenseComponent(NotFound)
      }
    ]
  }
]





export default SettingsMenuRoutes
