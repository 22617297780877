import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import PosOnlineContainer from 'client/containers/dashboard/Sales/containers/PosOnlineContainer'

// Pages
const PosOnlinePage = lazy(() => import('client/containers/dashboard/Sales/pages/PosOnlinePage'))
const PosOnlineCheckoutPage = lazy(() => import('client/containers/dashboard/Sales/pages/PosOnlineCheckoutPage'))
const PosOnlineSuccessPage = lazy(() => import('client/containers/dashboard/Sales/pages/PosOnlineSuccessPage'))

const PosOnlineRoutes = [
  {
    path: '/:username/sales/pos-online/:orders_id/:pageName',
    component: PosOnlineContainer,
    routes: [
      {
        path: '/:username/sales/pos-online/:orders_id/main',
        params: { pageName: 'main' },
        component: suspenseComponent(PosOnlinePage),
        exact: true,
      },
      {
        path: '/:username/sales/pos-online/:orders_id/checkout',
        params: { pageName: 'checkout', checkout: true },
        component: suspenseComponent(PosOnlineCheckoutPage),
        exact: true,
      },
      {
        path: '/:username/sales/pos-online/:orders_id/success/:bills_id',
        exact: true,
        params: { pageName: 'success', status: 'success' },
        component: suspenseComponent(PosOnlineSuccessPage),
      },
    ]
  }
]

export default PosOnlineRoutes
