// Containers
import ProductsContainer from 'client/containers/dashboard/Products/containers/ProductsContainer'

// Routes
import ProductsMenuRoutes from './routes/ProductsMenuRoutes'

// Routes Folders
import FolderUpdateRoutes from '../FoldersRoutes/routes/FolderUpdateRoutes'

// Routes Folders
import AttributeTypeUpdateRoutes from '../AttributesRoutes/routes/AttributeTypeUpdateRoutes'


const ProductsRoutes = [
  {
    path: '/:username/products',
    component: ProductsContainer,
    routes: [
      ...FolderUpdateRoutes({ path: '/:username/products', typePath: 'products' }),
      ...AttributeTypeUpdateRoutes('products'),
      ...ProductsMenuRoutes,
    ]
  }
]

export default ProductsRoutes
