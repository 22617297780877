import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'

//components
import { Box, ButtonLink, Title, Row } from 'components/UserInterface'
import ModalDefault from 'components/modal/ModalDefault'
import BtnLoading from 'components/button/BtnSubmit'

// Import Actions
import { checkConfirmAccountToken, confirmAccountToken } from 'core/redux/Auth/AuthActions'


class ConfirmAccountToken extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const signUpToken = this.props.match.params.token

    this.props.dispatch(checkConfirmAccountToken({
      body: { signUpToken: signUpToken },
      errorAlert: true
    })).then(res => {
      if (res.success) {
        this.setState({ token: 'valid' })
      } else {
        this.setState({ token: 'invalid' })
      }
    })
  }

  handleConfirmAccount = () => {
    const signUpToken = this.props.match.params.token

    this.props.dispatch(confirmAccountToken({
      body: { signUpToken: signUpToken },
      loadId: 'confirm-account-token',
      errorAlert: true,
      props: this.props,
      redirect: { url: '/' },
      toastMsg: 'ยืนยันการสมัครสมาชิกเรียบร้อยแล้ว'

    })).then(res => {
      if (res.success) {
        this.setState({ token: 'valid' })
      } else {
        this.setState({ token: 'invalid' })
      }
    })
  }

  render() {
    return (
      <div>
        <Helmet title="ยืนยันการสมัครสมาชิกของคุณ" />
        <ModalDefault showOnly={true} bgOverlay="#7cb342" width="500px">
          <Box padding="30px" background="#fff" textAlign="center">

          { !this.state.token &&
            <div>
              <p>กรุณารอสักครู่กำลังตรวจสอบ Token ...</p>
            </div>
          }

          { this.state.token === 'valid' &&
            <div>
              <p>คลิกปุ่ม ยืนยันการสมัครสมาชิก เพื่อยืนยันการสมัครสมาชิก</p>

              <BtnLoading
                loadingId="confirm-account-token"
                onClick={() => this.handleConfirmAccount() }
                icon="send"
                iconAlign="left"
                text="ยืนยันการสมัครสมาชิก"
                className="waves-effect waves-light btn light-green darken-1 mg-top-20"
              />
            </div>
          }

          { this.state.token === 'invalid' &&
            <div>
              <i className="material-icons font-4-0 red-text text-accent-4">error_outline</i>
              <p className="red-text text-accent-4">
                ไม่สามารถยืนยันการสมัครสมาชิกได้<br />เนื่องจาก Token ยืนยันการสมัครสมาชิก ของคุณไม่ถูกต้อง
              </p>

              <ButtonLink
                to={`/`}
                iconAlign="left"
                icon="reply"
                text="กลับไปยังหน้าแรก"
                margin="30px 0 0 0"
                className="waves-effect waves-light btn light-green darken-1"
              />
            </div>
          }

          </Box>
        </ModalDefault>

      </div>
    )
  }
}

export default withRouter(connect()(ConfirmAccountToken))
