import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import ProductsMenuContainer from 'client/containers/dashboard/Products/containers/ProductsMenuContainer'
import ProductUpdateContainer from 'client/containers/dashboard/Products/containers/ProductUpdateContainer'
import SiteManageRoutes from '../../SitesRoutes/routes/SiteManageRoutes'

// Components
const ProductsListContainer = lazy(() => import('client/containers/dashboard/Products/containers/ProductsListContainer'))
const ProductManagePage = lazy(() => import('client/containers/dashboard/Products/pages/ProductManagePage'))


const ProductsListPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductsListPage'))
const ProductStocksListPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductStocksListPage'))
const ProductFoldersListPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductFoldersListPage'))
const ProductAttributesListPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductAttributesListPage'))

// update
const DescriptionsPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductUpdate/DescriptionsPage'))
const ShippingFeesPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductUpdate/ShippingFeesPage'))
const AttributesPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductUpdate/AttributesPage'))
const ItemsListPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductUpdate/ItemsListPage'))
const PhotosPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductUpdate/PhotosPage'))
const ProductContentPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductContentPage'))
const ProductContentManagePage = lazy(() => import('client/containers/dashboard/Products/pages/ProductContentManagePage'))
const SalePageDesignPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductUpdate/SalePageDesignPage'))

const ProductsPrintBarcodePage = lazy(() => import('client/containers/dashboard/Products/pages/ProductsPrintBarcodePage'))
const ProductsImportPage = lazy(() => import('client/containers/dashboard/Products/pages/ProductsImportPage'))
const ProductsExportPages = lazy(() => import('client/containers/dashboard/Products/pages/ProductsExportPages'))

const status = ':status(all|open|close|trash)'

const ProductsMenuRoutes = [
  {
    path: '/:username/products',
    component: ProductsMenuContainer,
    routes: [
      {
        path: '/:username/products/print-barcode',
        component: ProductsPrintBarcodePage,
        auth: { reqAuthBiz: true, reqExpDate: true },
      },
      {
        path: '/:username/products/import',
        component: ProductsImportPage,
        auth: { reqAuthBiz: true, reqExpDate: true },
      },
      {
        path: '/:username/products/export',
        component: ProductsExportPages,
        auth: { reqAuthBiz: true, reqExpDate: true },
      },
      {
        path: '/:username/products/trash',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'trash', title: 'รายการสินค้า', icon: 'assignment_late' },
        component: suspenseComponent(ProductsListPage)
      },
      {
        path: '/:username/products/discounts',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', discount: true, title: 'โปรโมชั่นส่วนลด', icon: 'assignment_late' },
        component: suspenseComponent(ProductsListPage)
      },
      {
        path: '/:username/products/stock',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ProductStocksListPage)
      },
      {
        path: '/:username/products/folders',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ProductFoldersListPage)
      },
      {
        path: '/:username/products/folders/:folders_id',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ProductFoldersListPage)
      },
      {
        path: '/:username/products/attributes',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'คุณลักษณะสินค้า', icon: 'group_work' },
        component: suspenseComponent(ProductAttributesListPage)
      },

      {
        path: `/:username/products/${status}/:products_id`,
        component: suspenseComponent(ProductsListContainer),
        routes: [
          {
            path: `/:username/products/${status}/:products_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(ProductManagePage),
            routes: [
              {
                path: `/:username/products/${status}/:products_id/update`,
                component: ProductUpdateContainer,
                routes: [
                  {
                    path: `/:username/products/${status}/:products_id/update`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    component: suspenseComponent(DescriptionsPage)
                  },
                  {
                    path: `/:username/products/${status}/:products_id/update/items`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    component: suspenseComponent(ItemsListPage)
                  },
                  {
                    path: `/:username/products/${status}/:products_id/update/items/:items_id`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    component: suspenseComponent(ItemsListPage)
                  },
                  {
                    path: `/:username/products/${status}/:products_id/update/shipping-fees`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    component: suspenseComponent(ShippingFeesPage)
                  },
                  {
                    path: `/:username/products/${status}/:products_id/update/content`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    params: { contentGroupId: 'editorContent', linkContentManage: 'content-manage' },
                    component: suspenseComponent(ProductContentPage)
                  },
                  {
                    path: `/:username/products/${status}/:products_id/update/content-manage`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    params: { contentGroupId: 'editorContent', linkContentDisplay: 'content' },
                    component: suspenseComponent(ProductContentManagePage)
                  },

                  {
                    path: `/:username/products/${status}/:products_id/update/salepage-design`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    component: suspenseComponent(SalePageDesignPage)
                  },
                  ...SiteManageRoutes({
                    typePath: `/:username/products/${status}/:products_id/update/salepage-design`,
                    singlePageManage: true
                  }),
                ]
              },

              /*{
                path: `/:username/products/${status}/:products_id`,
                exact: true,
                auth: { reqAuthBiz: true, reqExpDate: true },
                component: suspenseComponent(DescriptionsPage)
              },
              {
                path: `/:username/products/${status}/:products_id/photos`,
                exact: true,
                auth: { reqAuthBiz: true, reqExpDate: true },
                component: suspenseComponent(PhotosPage)
              },
              {
                path: `/:username/products/${status}/:products_id/shipping-fees`,
                exact: true,
                auth: { reqAuthBiz: true, reqExpDate: true },
                component: suspenseComponent(ShippingFeesPage)
              },
              {
                path: `/:username/products/${status}/:products_id/attributes`,
                exact: true,
                auth: { reqAuthBiz: true, reqExpDate: true },
                component: suspenseComponent(AttributesPage)
              }*/
            ]
          },
        ]
      },
    ]
  }
]

export default ProductsMenuRoutes
