// Containers
import RubberInventoriesContainer from 'client/containers/dashboard/SystemRubber/RubberInventories/containers/RubberInventoriesContainer'


// Routes
import RubberInventoriesMenuRoutes from './routes/RubberInventoriesMenuRoutes'



const RubberInventoriesRoutes = [
  {
    path: '/:username/rubber-inventories',
    component: RubberInventoriesContainer,
    routes: [
      ...RubberInventoriesMenuRoutes,
    ]
  },
]

export default RubberInventoriesRoutes
