import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberCustomersMenuContainer from 'client/containers/dashboard/SystemRubber/RubberCustomerBuyers/containers/RubberCustomersMenuContainer'

// Components
const RubberCustomersListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberCustomerBuyers/containers/RubberCustomersListContainer'))
const RubberCustomerManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberCustomerBuyers/pages/RubberCustomerManagePage'))


const RubberCustomerBuyersMenuRoutes = [
  {
    path: '/:username/rubber-customer-buyers',
    component: RubberCustomersMenuContainer,
    routes: [
      {
        path: '/:username/rubber-customer-buyers/:status(trash)/:customers_id',
        component: suspenseComponent(RubberCustomersListContainer),
        routes: [
          {
            path: '/:username/rubber-customer-buyers/:status(trash)/:customers_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberCustomerManagePage),
          }
        ]
      },
      {
        path: '/:username/rubber-customer-buyers/:customers_id',
        component: suspenseComponent(RubberCustomersListContainer),
        routes: [
          {
            path: '/:username/rubber-customer-buyers/:customers_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberCustomerManagePage),
          }
        ]
      },
    ]
  }
]

export default RubberCustomerBuyersMenuRoutes
