import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import IntlWrapper from 'client/modules/Intl/IntlWrapper'
import renderRoutes from 'client/routes/renderRoutes'

// Import Routes
import routes from './routes'

// css global
import '../../themes/dist/css/materialize.global.css'
import '../../themes/dist/css/alice-carousel.global.css'
import '../../themes/dist/css/animate.min.global.css'
import '../../themes/dist/css/perfect-scrollbar.global.css'
import '../../themes/dist/css/quill-editor.global.css'

import '../../themes/dist/css/color.global.css'
import '../../themes/dist/css/modifies.global.css'

import '../../themes/dist/css/position.global.css'
import '../../themes/dist/css/responsive.global.css'
import '../../themes/dist/css/structure.global.css'
import '../../themes/dist/css/section-container.css'
import '../../themes/dist/css/text-editor.global.css'
import '../../themes/dist/css/text.global.css'
import '../../themes/dist/css/ui.global.css'
import '../../themes/dist/css/form.global.css'

import '../../themes/dist/css/font.global.scss'
import '../../themes/dist/css/padding.global.scss'
import '../../themes/dist/css/margin.global.scss'
import '../../themes/dist/css/border.global.scss'
import '../../themes/dist/css/border-radius.global.scss'
import '../../themes/dist/css/width-height.global.scss'


export default function App(props) {
  return (
    <Provider store={props.store}>
      <IntlWrapper>
        <BrowserRouter>
          { renderRoutes({ routes }) }
        </BrowserRouter>
      </IntlWrapper>
    </Provider>
  )
}


App.propTypes = {
  store: PropTypes.object.isRequired,
}
