import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberReceiptsMenuContainer from 'client/containers/dashboard/SystemRubber/RubberReceipts/containers/RubberReceiptsMenuContainer'

// Bills
const RubberReceiptsListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberReceipts/containers/RubberReceiptsListContainer'))
const RubberReceiptManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberReceipts/pages/RubberReceiptManagePage'))

// Types
const saleType = ":saleType(all|sell-on-cash|deposit|contract|make)"
const group = ":group(all|paid|unpaid)"


const RubberReceiptsMenuRoutes = [
  {
    path: `/:username/rubber-receipts`,
    component: RubberReceiptsMenuContainer,
    routes: [
      {
        path: `/:username/rubber-receipts/${saleType}/${group}/:sales_id`,
        component: suspenseComponent(RubberReceiptsListContainer),
        routes: [
          {
            path: `/:username/rubber-receipts/${saleType}/${group}/:sales_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberReceiptManagePage)
          },
        ]
      }
    ]
  },
]

export default RubberReceiptsMenuRoutes
