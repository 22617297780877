import { getSystemsAttributes } from "core/functions/getAdminRole"

export const settingValue = ({ setting, lang='local' }) => {
  let paymentMethodsList

  // general
  if (setting) {
    paymentMethodsList = getPaymentMethodsList({ paymentMethods: setting.paymentMethods, lang })

    return {
      ...setting,
      paymentMethodsList
    }
  }
}

export const getPaymentMethodsList = ({ paymentMethods, lang='local' }) => {
  const payments = []

  if (paymentMethods) {
    const { bankTransfer, cashOnDelivery, creditCard, inStorePayment } = paymentMethods

    if (bankTransfer && bankTransfer.status) {
      payments.push({
        id: 'bankTransfer',
        nameLang: 'โอนเงิน/ชำระผ่านบัญชีธนาคาร',
        ...bankTransfer
      })
    }

    if (cashOnDelivery && cashOnDelivery.status) {
      payments.push({
        id: 'cashOnDelivery',
        nameLang: 'ชำระเงินปลายทาง',
        ...cashOnDelivery
      })
    }

    if (creditCard && creditCard.status) {
      payments.push({
        id: 'creditCard',
        nameLang: 'บัตรเครดิต/บัตรเดบิต',
        ...creditCard
      })
    }

    if (creditCard && creditCard.status) {
      payments.push({
        id: 'creditCard',
        nameLang: 'บัตรเครดิต/บัตรเดบิต',
        ...creditCard
      })
    }

    if (inStorePayment && inStorePayment.status) {
      payments.push({
        id: 'inStorePayment',
        nameLang: 'ชำระเงินที่ร้าน',
        ...inStorePayment
      })
    }
  }

  return payments
}


export const getDefaultSystemsAll = ({ attributes }) => {
  let systemsAttributes = []

  if (attributes) {
    systemsAttributes = getSystemsAttributes({ attributes })
  }

  const systemsAll = [
    {
      id: 'sell-online',
      name: 'ขายออนไลน์',
      link: '/sell-online/all/all',
    },
    {
      id: 'products',
      name: 'สินค้า',
      link: '/products/all/all',
    },
    {
      id: 'vouchers',
      name: 'คูปองส่วนลด',
      link: '/vouchers',
    },
    {
      id: 'bills',
      name: 'บัญชี',
      link: '/bills/quotation/all',
    },
    {
      id: 'articles',
      name: 'บทความ',
      link: '/articles/all/all',
    },
    {
      id: 'customer-affiliates',
      name: 'ตัวแทนจำหน่าย',
      link: '/customer-affiliates/all/all',
    },
    {
      id: 'customers',
      name: 'รายชื่อลูกค้า',
      link: '/customers/all/all',
    },
    {
      id: 'sites',
      name: 'จัดการเว็บไซต์',
      link: '/sites',
    },
    {
      id: 'admins',
      name: 'ผู้ดูและบบ',
      link: '/admins/all/all',
    },
    {
      id: 'attributes',
      name: 'จัดการคุณลักษณะ',
      link: '/attributes',
    },
    {
      id: 'users',
      name: 'ผู้ใช้งาน',
      link: '/users/all/all',
    },
    {
      id: 'settings',
      name: 'จัดการข้อมูลกิจการ',
      link: '/settings',
    },

    ...systemsAttributes
  ]

  return systemsAll
}
