const app = {
  dev: {
    container: 'store',
    project: 'store',
    ssr: true,
    host: {
      websites: {
        protocol: 'http',
        hostname: 'localhost:4100'
      }
    },
    cdnUrl: 'https://cdn.addner.com',
    apiUrl: 'http://localhost:1001/api',
    facebook: {
      appId: '1466384967177769'
    },
    google: {
      appId: '425040221548-hug8uqefh9i33jpq99li078ehmtbiaqr.apps.googleusercontent.com'
    },
    s3: {
      url: 'https://s3-ap-southeast-1.amazonaws.com/images.apps.com',
      urlOriginal: 'https://s3-ap-southeast-1.amazonaws.com/original-images.apps.com'
    },
    googleMaps: {
      key: 'AIzaSyAhpxtIOthrW2SViWcGpO0UWSR8sDZbWpI'
    }
  },
  prod: {
    container: 'store',
    project: 'store',
    ssr: false,
    host: {
      websites: {
        protocol: 'https',
        hostname: 'addner.com'
      }
    },
    cdnUrl: 'https://cdn.addner.com',
    apiUrl: 'https://api-store.addner.com/api',
    facebook: {
      appId: '1466384967177769'
    },
    google: {
      appId: '425040221548-hug8uqefh9i33jpq99li078ehmtbiaqr.apps.googleusercontent.com'
    },
    s3: {
      url: 'https://s3-ap-southeast-1.amazonaws.com/images.apps.com',
      urlOriginal: 'https://s3-ap-southeast-1.amazonaws.com/original-images.apps.com'
    },
    googleMaps: {
      key: 'AIzaSyAhpxtIOthrW2SViWcGpO0UWSR8sDZbWpI'
    }
  }
}

export default app
