import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import AttributesMenuContainer from 'client/containers/dashboard/Attributes/containers/AttributesMenuContainer'

// Components
const AttributesListPage = lazy(() => import('client/containers/dashboard/Attributes/pages/AttributesListPage'))



const AttributesMenuRoutes = [
  {
    path: '/:username/attributes',
    component: AttributesMenuContainer,
    routes: [
      {
        path: '/:username/attributes',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', type: 'product', title: 'คุณลักษณะสินค้า', icon: 'group_work' },
        component: suspenseComponent(AttributesListPage)
      },
      {
        path: '/:username/attributes/article',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', type: 'article', title: 'คุณลักษณะบทความ', icon: 'group_work' },
        component: suspenseComponent(AttributesListPage)
      },
      {
        path: '/:username/attributes/note',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', type: 'note', title: 'คุณลักษณะบันทึก', icon: 'group_work' },
        component: suspenseComponent(AttributesListPage)
      },
      {
        path: '/:username/attributes/customer',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', type: 'customer', title: 'คุณลักษณะลูกค้า', icon: 'group_work' },
        component: suspenseComponent(AttributesListPage)
      }
    ]
  }
]

export default AttributesMenuRoutes
