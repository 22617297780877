// Containers
import RubberProductsContainer from 'client/containers/dashboard/SystemRubber/RubberProducts/containers/RubberProductsContainer'

// Routes
import RubberProductsMenuRoutes from './routes/RubberProductsMenuRoutes'



const RubberProductsRoutes = [
  {
    path: '/:username/rubber-products',
    component: RubberProductsContainer,
    routes: [
      ...RubberProductsMenuRoutes,
    ]
  }
]

export default RubberProductsRoutes
