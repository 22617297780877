import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import SitesMenuContainer from 'client/containers/dashboard/Sites/containers/SitesMenuContainer'

// Components
const SitesListPage = lazy(() => import('client/containers/dashboard/Sites/pages/SitesListPage'))


const SitesMenuRoutes = [
  {
    path: '/:username/sites',
    component: SitesMenuContainer,
    routes: [
      {
        path: '/:username/sites',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'เว็บไซต์ทั้งหมด', icon: 'assignment_late' },
        component: suspenseComponent(SitesListPage)
      },
      {
        path: '/:username/sites/trash',
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'trash', title: 'เว็บไซต์ที่ถูกลบ', icon: 'assignment_late' },
        component: suspenseComponent(SitesListPage)
      },
    ]
  }
]

export default SitesMenuRoutes
