// Containers
import RubberDepositsContainer from 'client/containers/dashboard/SystemRubber/RubberDeposits/containers/RubberDepositsContainer'

// Routes
import RubberDepositsMenuRoutes from './routes/RubberDepositsMenuRoutes'



const RubberDepositsRoutes = [
  {
    path: '/:username/rubber-deposits',
    component: RubberDepositsContainer,
    routes: [
      ...RubberDepositsMenuRoutes
    ]
  }
]

export default RubberDepositsRoutes
