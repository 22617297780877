import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
//const SiteManageContainer = lazy(() => import('client/containers/dashboard/Sites/containers/SiteManageContainer'))
import SiteManageContainer from 'client/containers/dashboard/Sites/containers/SiteManageContainer'


// Components Pages
//const PageManagePage = lazy(() => import('client/containers/dashboard/Sites/pages/PageManagePage'))
import PageManagePage from 'client/containers/dashboard/Sites/pages/PageManagePage'

const SiteDisplayPage = lazy(() => import('client/containers/dashboard/Sites/pages/SiteDisplayPage'))



const SiteManageRoutes = ({ typePath, siteManage, singlePageManage }) => [
  {
    path: `${typePath}/site-display-fullscreen`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { fullscreen: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteDisplayPage)
  },
  {
    path: `${typePath}/site-manage/site-display`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { manage: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteDisplayPage)
  },
  {
    path: `${typePath}/site-manage/header-manage`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { header: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },
  {
    path: `${typePath}/site-manage/footer-manage`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { footer: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },
  {
    path: `${typePath}/site-manage/widget-manage`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { widget: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },


  {
    path: `${typePath}/site-manage/content-group-page/:content_group_page_id`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { contentGroupPage: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },
  {
    path: `${typePath}/site-manage/content-group-page/:content_group_page_id/header`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { contentGroupPage: true, contentGroupPageHeader: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },
  {
    path: `${typePath}/site-manage/content-group-page/:content_group_page_id/header-fixed`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { contentGroupPage: true, contentGroupPageHeaderFixed: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },
  {
    path: `${typePath}/site-manage/content-group-page/:content_group_page_id/footer`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { contentGroupPage: true, contentGroupPageFooter: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },


  {
    path: `${typePath}/site-manage/template-manage/:templates_id`,
    exact: true,
    auth: { reqAuthBiz: true, reqExpDate: true },
    params: { template: true, siteManage, singlePageManage },
    component: suspenseComponent(SiteManageContainer)
  },
  {
    path: `${typePath}/site-manage/page-template-manage/:path_1`,
    component: SiteManageContainer,
    params: { pageTemplate: true, siteManage, singlePageManage },
    routes: [
      {
        path: `${typePath}/site-manage/page-template-manage/:path_1`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { pageTemplate: true, siteManage, singlePageManage },
        component: PageManagePage
      }
    ]
  },
  {
    path: `${typePath}/site-manage/:path_1?/:path_2?/:path_3?/:path_4?`,
    component: SiteManageContainer,
    params: { page: true, siteManage, singlePageManage },
    routes: [
      {
        path: `${typePath}/site-manage/:path_1?/:path_2?/:path_3?/:path_4?`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { page: true, siteManage, singlePageManage },
        component: PageManagePage
      }
    ]
  }
]

export default SiteManageRoutes


