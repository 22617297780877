import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// containers
const BusinessContainer = lazy(() => import('client/containers/dashboard/Businesses/containers/BusinessContainer'))

// Routes
/*
import ToursRoutes from './routes/ToursRoutes'
import ServicesRoutes from './routes/ServicesRoutes'
import PlacesRoutes from './routes/PlacesRoutes'
import SalesNotesRoutes from './routes/SalesNotesRoutes'*/
//import ReserveToursRoutes from './routes/ReserveToursRoutes'

// common
import AdminsRoutes from 'core/routes/AdminsRoutes'
import UsersRoutes from 'core/routes/UsersRoutes'

// local
import POSRoutes from './routes/POSRoutes'
import SellOnlineRoutes from './routes/SellOnlineRoutes'
import SalesRoutes from './routes/SalesRoutes'
import BillsRoutes from './routes/BillsRoutes'
//import UsersRoutes from './routes/UsersRoutes'
import CustomersRoutes from './routes/CustomersRoutes'
import CustomerAffiliatesRoutes from './routes/CustomerAffiliatesRoutes'
import SettingsRoutes from './routes/SettingsRoutes'

import ProductsRoutes from './routes/ProductsRoutes'
import SitesRoutes from './routes/SitesRoutes'
import VouchersRoutes from './routes/VouchersRoutes'
import ArticlesRoutes from './routes/ArticlesRoutes'
import AttributesRoutes from './routes/AttributesRoutes'
import NotesRoutes from './routes/NotesRoutes'

import PlacesRoutes from './routes/PlacesRoutes'
import CommonSettingsRoutes from './routes/CommonSettingsRoutes'

// rubber
import RubberPurchasesRoutes from './routes/SystemRubber/RubberPurchasesRoutes'
import RubberSalesRoutes from './routes/SystemRubber/RubberSalesRoutes'
import RubberPaymentsRoutes from './routes/SystemRubber/RubberPaymentsRoutes'
import RubberProductsRoutes from './routes/SystemRubber/RubberProductsRoutes'
import RubberCustomerSellersRoutes from './routes/SystemRubber/RubberCustomerSellersRoutes'
import RubberCustomerBuyersRoutes from './routes/SystemRubber/RubberCustomerBuyersRoutes'
import RubberInventoriesRoutes from './routes/SystemRubber/RubberInventoriesRoutes'
import RubberContractsRoutes from './routes/SystemRubber/RubberContractsRoutes'
import RubberDepositsRoutes from './routes/SystemRubber/RubberDepositsRoutes'
import RubberReceiptsRoutes from './routes/SystemRubber/RubberReceiptsRoutes'
import RubberReceivablesRoutes from './routes/SystemRubber/RubberReceivablesRoutes'
import RubberReportsRoutes from './routes/SystemRubber/RubberReportsRoutes'

// Component Pages
import BusinessDashboardContainer from 'client/containers/dashboard/Businesses/containers/BusinessDashboardContainer/index'


const BusinessesRoutes = [
  {
    path: '/:username',
    component: suspenseComponent(BusinessContainer),
    routes: [
      {
        path: '/:username',
        auth: { reqAuthBiz: true },
        component: BusinessDashboardContainer,
        routes: [
          ...UsersRoutes,
          ...AdminsRoutes,

          ...PlacesRoutes,
          ...SitesRoutes,
          ...POSRoutes,
          ...SellOnlineRoutes,
          ...SalesRoutes,
          ...ProductsRoutes,
          ...VouchersRoutes,
          ...ArticlesRoutes,
          ...BillsRoutes,
          /*...ToursRoutes,
          ...ServicesRoutes,
          ...PlacesRoutes,
          ...SalesNotesRoutes,*/
          ...CustomerAffiliatesRoutes,
          ...CustomersRoutes,
          ...AttributesRoutes,
          ...NotesRoutes,
          ...SettingsRoutes,
          ...CommonSettingsRoutes,


          ...RubberPurchasesRoutes,
          ...RubberSalesRoutes,
          ...RubberPaymentsRoutes,
          ...RubberProductsRoutes,
          ...RubberCustomerSellersRoutes,
          ...RubberCustomerBuyersRoutes,
          ...RubberInventoriesRoutes,
          ...RubberContractsRoutes,
          ...RubberDepositsRoutes,
          ...RubberReceiptsRoutes,
          ...RubberReceivablesRoutes,
          ...RubberReportsRoutes
        ]
      }
    ]
  }
]

export default BusinessesRoutes
