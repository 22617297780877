import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import CustomersMenuContainer from 'client/containers/dashboard/Customers/containers/CustomersMenuContainer'

// Components
const CustomersListContainer = lazy(() => import('client/containers/dashboard/Customers/containers/CustomersListContainer'))
const CustomerManagePage = lazy(() => import('client/containers/dashboard/Customers/pages/CustomerManagePage'))


const status = ':status(all|open|close|trash)'

const CustomersMenuRoutes = [
  {
    path: '/:username/customers',
    component: CustomersMenuContainer,
    routes: [
      {
        path: `/:username/customers/${status}/:customers_id`,
        component: suspenseComponent(CustomersListContainer),
        routes: [
          {
            path: `/:username/customers/${status}/:customers_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(CustomerManagePage),
          }
        ]
      }
    ]
  }
]

export default CustomersMenuRoutes
