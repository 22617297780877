import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberPaymentsMenuContainer from 'client/containers/dashboard/SystemRubber/RubberPayments/containers/RubberPaymentsMenuContainer'

// Components
const RubberPaymentsUnpaidListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberPayments/containers/RubberPaymentsUnpaidListContainer'))
const RubberPaymentsUnpaidMemberManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberPayments/pages/RubberPaymentsUnpaidMemberManagePage'))

const RubberPaymentsBillsListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberPayments/containers/RubberPaymentsBillsListContainer'))
const RubberPaymentsBillManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberPayments/pages/RubberPaymentsBillManagePage'))

const RubberPaymentsMenuRoutes = [
  {
    path: '/:username/rubber-payments',
    component: RubberPaymentsMenuContainer,
    routes: [
      {
        path: '/:username/rubber-payments/unpaid/:withdraw_type/:customers_id?/:customers_members_id?/:customers_employees_id?',
        component: suspenseComponent(RubberPaymentsUnpaidListContainer),
        routes: [
          {
            path: '/:username/rubber-payments/unpaid/:withdraw_type/:customers_id?/:customers_members_id?/:customers_employees_id?',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberPaymentsUnpaidMemberManagePage)
          },
        ]
      },
      {
        path: '/:username/rubber-payments/voucher-payment/:bills_id',
        component: suspenseComponent(RubberPaymentsBillsListContainer),
        routes: [
          {
            path: '/:username/rubber-payments/voucher-payment/:bills_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberPaymentsBillManagePage)
          }
        ]
      },
    ]
  }
]

export default RubberPaymentsMenuRoutes
