import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import ArticlesMenuContainer from 'client/containers/dashboard/Articles/containers/ArticlesMenuContainer'
import ArticleUpdateContainer from 'client/containers/dashboard/Articles/containers/ArticleUpdateContainer'

// Components
const ArticlesListContainer = lazy(() => import('client/containers/dashboard/Articles/containers/ArticlesListContainer'))
const ArticleManagePage = lazy(() => import('client/containers/dashboard/Articles/pages/ArticleManagePage'))

// Components
const ArticleFoldersListPage = lazy(() => import('client/containers/dashboard/Articles/pages/ArticleFoldersListPage'))
const ArticleTagsListPage = lazy(() => import('client/containers/dashboard/Articles/pages/ArticleTagsListPage'))

// Components Update
const ArticleContentPage = lazy(() => import('client/containers/dashboard/Articles/pages/ArticleContentPage'))
const ArticleContentManagePage = lazy(() => import('client/containers/dashboard/Articles/pages/ArticleContentManagePage'))
const ArticleAttributesPage = lazy(() => import('client/containers/dashboard/Articles/pages/ArticleAttributesPage'))

const status = ':status(all|open|close|trash)'

const ArticlesMenuRoutes = ({ path }) => [
  {
    path: `${path}`,
    component: ArticlesMenuContainer,
    routes: [
      {
        path: `${path}/${status}/:articles_id`,
        component: suspenseComponent(ArticlesListContainer),
        routes: [
          {
            path: `${path}/${status}/:articles_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(ArticleManagePage),
            routes: [
              {
                path: `${path}/${status}/:articles_id/update`,
                component: ArticleUpdateContainer,
                routes: [
                  {
                    path: `${path}/${status}/:articles_id/update/content`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    params: { contentGroupId: 'editorContent', linkContentManage: 'content-manage' },
                    component: suspenseComponent(ArticleContentPage)
                  },
                  {
                    path: `${path}/${status}/:articles_id/update/content-manage`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    params: { contentGroupId: 'editorContent', linkContentDisplay: 'content' },
                    component: suspenseComponent(ArticleContentManagePage)
                  },
                  {
                    path: `${path}/${status}/:articles_id/update/attributes`,
                    exact: true,
                    auth: { reqAuthBiz: true, reqExpDate: true },
                    component: suspenseComponent(ArticleAttributesPage)
                  },
                ]
              }
            ]
          },
        ]
      },

      {
        path: `${path}/folders`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ArticleFoldersListPage)
      },
      {
        path: `${path}/folders/:folders_id`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ArticleFoldersListPage)
      },

      {
        path: `${path}/tags`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ArticleTagsListPage)
      },
    ]
  }
]

export default ArticlesMenuRoutes
