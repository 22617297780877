import React from 'react'
import renderRoutes from '../../../../../routes/renderRoutes'
import { connect } from 'react-redux'

// Components Global
import Window from '../../../../../components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from '../../../../../components/LayoutsTwoResponsive'
import MenuSidebarResponsive from '../../../../../components/MenuSidebarResponsive'

// Components Purchases
import RubberPurchaseCreateAndUpdateModal from '../components/RubberPurchaseCreateAndUpdateModal'

// imgs
import titleIcon from '../resources/imgs/purchases.png'


const menuItems = (params, setting) => {
  const { username } = params
  const { types } = setting
  const type = types[0]
  const path = `/${username}/rubber-purchases`
  let reserveMenu = []

  if (type.reserve) {
    reserveMenu = [
      {
        name: 'การสำรองจ่าย',
        title: true
      },

      {
        name: 'ยังไม่กรอก % ยาง',
        nameShort: 'ยังไม่กรอก % ยาง',
        icon: 'edit_off',
        link: `${path}/reserve/estimate/all`,
        searchLinkActive: `${path}/reserve/estimate/`
      },
      {
        name: 'กรอก % ยางแล้ว',
        nameShort: 'กรอก % ยางแล้ว',
        icon: 'library_add_check',
        link: `${path}/reserve/finish/all`,
        searchLinkActive: `${path}/reserve/finish/`
      },
    ]
  }

  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'บันทึกการซื้อยาง',
      nameShort: 'บันทึกการซื้อ',
      icon: 'assignment',
      link: `${path}/all/all/all`,
      searchLinkActive: `${path}/all/all/`
    },

    ...reserveMenu
  ]
}

const RubberPurchasesMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="บันทึกการซื้อยาง"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            btnAddModal={{
              modalId: 'purchaseCreateModal',
              text: 'เพิ่มบันทึกการซื้อยาง',
            }}
            items={menuItems(match.params, setting)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>

      { props.modal &&
        <RubberPurchaseCreateAndUpdateModal {...props} event="create" id="purchaseCreateModal" match={match} business={business} />
      }
    </Window>
  )
}

function mapStateToProps(store, props) {
  return {
    modal: store.app.modals.filter(modal => modal.id === 'purchaseCreateModal')[0]
  }
}

export default connect(mapStateToProps)(RubberPurchasesMenuContainer)
