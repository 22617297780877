// Containers
import SitesContainer from 'client/containers/dashboard/Sites/containers/SitesContainer'
import SiteContainer from 'client/containers/dashboard/Sites/containers/SiteContainer'

// Routes
import SitesMenuRoutes from './routes/SitesMenuRoutes'
import SiteUpdateRoutes from './routes/SiteUpdateRoutes'
import SiteManageRoutes from './routes/SiteManageRoutes'

const SitesRoutes = [
  {
    path: '/:username/sites',
    component: SitesContainer,
    routes: [
      {
        path: '/:username/sites/:sites_id',
        component: SiteContainer,
        auth: { reqAuthBiz: true, reqExpDate: true },
        routes: [
          ...SiteManageRoutes({ typePath: '/:username/sites/:sites_id/update', siteManage: true }),
          ...SiteUpdateRoutes,
        ]
      },
      ...SitesMenuRoutes
    ]
  }
]

export default SitesRoutes
