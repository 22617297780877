import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberSalesMenuContainer from 'client/containers/dashboard/SystemRubber/RubberSales/containers/RubberSalesMenuContainer'

// Components
const RubberSalesListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberSales/containers/RubberSalesListContainer'))
const RubberSaleManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberSales/pages/RubberSaleManagePage'))

// Types
const productType = ":productType(field-latex|rubber-sheet|rubber-sheet-make)"
const rubberType = ":rubberType(all|field-latex|rubber-sheet|ribbed-smoked-sheet)"
const saleType = ":saleType(sell-on-cash|make|deposit|contract)"


const RubberSalesMenuRoutes = [
  {
    path: `/:username/rubber-sales/${productType}/${rubberType}/${saleType}`,
    component: RubberSalesMenuContainer,
    params: { saleType: 'sell-on-cash' },
    routes: [
      {
        path: `/:username/rubber-sales/${productType}/${rubberType}/${saleType}/sales/:sales_id`,
        component: suspenseComponent(RubberSalesListContainer),
        routes: [
          {
            path: `/:username/rubber-sales/${productType}/${rubberType}/${saleType}/sales/:sales_id`,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberSaleManagePage)
          },
        ]
      }
    ]
  },
]

export default RubberSalesMenuRoutes
