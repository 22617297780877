import React, { Fragment, useEffect, useState } from 'react'

// Components Global
import { Row, Column } from '../../../../../../components/Columns'

// Redux
import { convertDecimal } from '../../../../../../redux/RubberSettings/RubberSettingsValue'
import {
  RubberInventorySelectDates,
  TitleShow,
  InputNumber
} from './RubberSaleForms'

import { getRubberSettingTypeValue } from '../../../../../../redux/RubberSettings/RubberSettingsValue'


const InventoryForms = (props) => {
  const [inventories, setInventories] = useState([])
  const [netWeightOrg, setNetWeightOrg] = useState(0)
  const [netWeightSale, setNetWeightSale] = useState(0)
  const [dryLatexWeight, setDryLatexWeight] = useState(0)
  const [latexPercentAvg, setLatexPercentAvg] = useState(0)
  const [netPriceAvg, setNetPriceAvg] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const { type } = props

  useEffect(() => {
    if (props.cost) {
      setInventories(props.cost.inventories)
      setNetWeightOrg(props.cost.netWeightOrg)
      setNetWeightSale(props.cost.netWeightSale)
      setNetPriceAvg(props.cost.netPriceAvg)
      setDryLatexWeight(props.cost.dryLatexWeight)
      setLatexPercentAvg(props.cost.latexPercentAvg)
      setTotalPrice(props.cost.totalPrice)
    }
  }, [])

  const setStateAll = ({ inventories }) => {
    let netWeightOrg = 0, totalPriceOrg = 0, dryLatexWeightOrg = 0
    let netWeightSale = 0, totalPrice = 0, dryLatexWeight = 0, netPriceAvg = 0, latexPercentAvg = 0

    inventories.map(inventory => {
      netWeightSale += inventory.netWeightSale
      netPriceAvg += inventory.netPriceAvg
      latexPercentAvg += inventory.latexPercentAvg

      netWeightOrg += inventory.netWeightOrg
      totalPriceOrg += inventory.totalPrice
      dryLatexWeightOrg += inventory.dryLatexWeight

      const dryLatexWeightFixed = inventory.netWeightSale * inventory.latexPercentAvg / 100
      const totalPriceFixed = dryLatexWeightFixed * inventory.netPriceAvg

      dryLatexWeight += dryLatexWeightFixed
      totalPrice += totalPriceFixed
    })

    netPriceAvg = (totalPrice/dryLatexWeight) || 0
    latexPercentAvg = (dryLatexWeight/netWeightSale*100) || 0

    setInventories(inventories)
    setNetWeightOrg(netWeightOrg)
    setNetWeightSale(netWeightSale)
    setNetPriceAvg(netPriceAvg)
    setDryLatexWeight(dryLatexWeight)
    setLatexPercentAvg(latexPercentAvg)
    setTotalPrice(totalPrice)

    const cost = {
      inventories,
      netWeightOrg,
      netWeightSale,
      netPriceAvg,
      latexPercentAvg,
      dryLatexWeight,
      totalPrice
    }

    props.onChange(cost)
  }

  const onChangeDates = (items) => {
    const inventories = []

    items.map(item => {
      const {
        date,
        purchases,
        netPriceAvg,
        latexPercentAvg,
        netWeight,
        netWeightOrg,
        dryLatexWeight,
        _id,
        sales_id,
        deposits_id,
        totalPrice
      } = item

      inventories.push({
        date,
        netPriceAvg,
        latexPercentAvg,
        dryLatexWeight,
        totalPrice,
        inventories_id: _id,
        sales_id,
        deposits_id,
        netWeightOrg,
        netWeight,
        netWeightSale: netWeight,
        purchases
      })
    })

    setStateAll({ inventories })
  }


  const handleNetWeightSaleChange = (value, i) => {
    const newInventories = [...inventories]
    newInventories[i].netWeightSale = parseFloat(value || 0)
    setStateAll({ inventories: newInventories})
  }

  const col = 'column col-xxs-12 col-xs-6 col-sm-6 col-md-3 col-lg-4 pd-left-10 pd-right-10'
  const col_2 = 'column col-xxs-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 pd-left-10 pd-right-10'
  const { setting } = props

  const typeLatex = getRubberSettingTypeValue({ setting, code: 'field-latex' })
  const typeRubberSheet = getRubberSettingTypeValue({ setting, code: 'rubber-sheet' })

  return (
    <div>
      <Row className="row-dp-flex mg-left--10 mg-right--10">
        <Column col={`column col-xxs-12 pd-left-10 pd-right-10`}>
          <RubberInventorySelectDates
            {...props}
            onChange={onChangeDates}
          />
        </Column>

        { inventories.map((inventory, i) => {
          return (
            <Fragment key={i}>
              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.date} type="date-short" label="วันที่" />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.latexPercentAvg} type="number" label="% ยาง" />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.netPriceAvg} type="xxx" label="ราคา/กก." />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <TitleShow value={inventory.netWeight} type="number" label="นน.ยาง" />
              </Column>

              <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
                <InputNumber
                  value={inventory.netWeightSale}
                  onChange={(value) => handleNetWeightSaleChange(value, i)}
                  maxValue={inventory.netWeight}
                  required
                  type="number"
                  label="นน.ยางที่ขาย"
                />
              </Column>
            </Fragment>
          )
        }) }

        <Column col={`column col-xxs-12 pd-left-10 pd-right-10 pd-bottom-10`}>
          <div className="font-1-1">สรุปต้นทุน</div>
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={netWeightSale} type="number" label="นน.ยาง" />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={latexPercentAvg} type="number" label="% ยาง" />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={dryLatexWeight} type="number" label="นน.ยางแห้ง" />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={netPriceAvg} type="number" label="ราคา/กก." />
        </Column>

        <Column col={`column col-xxs-2-4 pd-left-10 pd-right-10`}>
          <TitleShow value={totalPrice} type="number" label="รวมเป็นเงิน" />
        </Column>
      </Row>
    </div>
  )
}

export default InventoryForms
