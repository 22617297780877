import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import POSMainContainer from 'client/containers/dashboard/POS/containers/POSMainContainer'

// Pages
import POSPage from 'client/containers/dashboard/POS/pages/POSPage'
import PaymentPage from 'client/containers/dashboard/POS/pages/PaymentPage'
import HistoriesPage from 'client/containers/dashboard/POS/pages/HistoriesPage'
import OrderDetailsPage from 'client/containers/dashboard/POS/pages/OrderDetailsPage'

const SuccessedPage = lazy(() => import('client/containers/dashboard/POS/pages/SuccessedPage'))
const POSMainRoutes = [
  {
    path: '/:username/pos',
    component: POSMainContainer,
    routes: [
      {
        path: `/:username/pos/sales/:orders_drafts_id/:page?/:orders_id?`,
        component: suspenseComponent(POSPage),
        routes: [
          {
            path: `/:username/pos/sales/:orders_drafts_id/payment`,
            params: { event_name: 'payment' },
            exact: true,
            component: suspenseComponent(PaymentPage),
          },
          {
            path: `/:username/pos/sales/:orders_drafts_id/payment/successed`,
            params: { event_name: 'successed' },
            exact: true,
            component: suspenseComponent(SuccessedPage),
          },
          {
            path: `/:username/pos/sales/:orders_drafts_id/histories/:orders_id`,
            component: HistoriesPage,
            routes: [
              {
                path: `/:username/pos/sales/:orders_drafts_id/histories/:orders_id`,
                exact: true,
                component: OrderDetailsPage,
              }
            ]
          },
        ]
      },

      /*{
        path: `/:username/sales/export`,
        component: suspenseComponent(OrdersExportPages),
      }*/
    ]
  }
]

export default POSMainRoutes
