import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components Global
import ProfileAvatar from 'components/ProfileAvatar'
import DropdownDefault from 'components/DropdownDefaultV2'

// Values
import { userValue } from 'core/redux/Users/UsersValues'

// Import Style
import styles from './Header.css';
import logo from  './logo.png'


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { auth } = this.props
    let user = auth && auth.user
    user = user && userValue({ user })

    return (
      <div className="height-60 box-shadow-2" style={{ background: '#212527' }}>
        <div className="height-60 line-height-60" >
          <div className="width-70 height-60 line-height-60 center left">
            <Link to={`/`}>
              <img className="width-40 mg-top-10" src={logo} />
            </Link>
          </div>

          <div className="left font-1-0 color-fff menu-servives height-60 line-height-60 hidden-xxs hidden-xs hidden-sm hidden-md">
            ระบบจัดการธุรกิจ
          </div>

          { auth.user !== 'unauthorized'  ?
            <div className="right">
              <DropdownDefault
                className="width-full"
                contentClassName="min-width-250 right-10 top-60">

                <div className={`pd-left-10 pd-right-10 dp-inline-block`} style={{ cursor: 'pointer' }}>
                  <table style={{ width: 'auto' }}>
                    <tbody>
                      <tr>
                        <td className="pd-0 width-50">
                          <div className="width-40 height-40">
                            <ProfileAvatar
                              circle
                              images={user.images}
                              imageSize="m"
                              color={user.avatarColor}
                              texts={user.avatarName}
                              textSize="0.8rem"
                            />
                          </div>
                        </td>
                        <td className="pd-0 pd-right-10 max-width-150 user-select-none">
                          <div className="hidden-xs color-fff font-0-9 line-height-60 hidden-sm text-ellipsis max-width-140">
                          { user.nameLang }
                          </div>
                        </td>
                        <td className="pd-0 width-20 color-fff">
                          <span className="font-1-0">▼</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td className="pd-10 width-70">
                          <div className="width-50 height-50">
                            <ProfileAvatar
                              circle
                              images={user.images}
                              imageSize="m"
                              color={user.avatarColor}
                              texts={user.avatarName}
                              textSize="1rem"
                            />
                          </div>
                        </td>
                        <td className="pd-0 line-height-20">
                          <span className="dp-block color-000 font-0-9">
                          { user.nameLang }
                          </span>
                          <span className="dp-block color-999 font-0-8">
                            { user.email }
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-right pd-10 line-height-0 border-top-1 border-top-eee">
                    <Link to={`/signout`} className="btn height-30 pd-left-10 pd-right-10 line-height-30 btn-shadow-none font-0-9">
                      <i className="material-icons left mg-right-10">power_settings_new</i> ออกจากระบบ
                    </Link>
                  </div>
                </div>
              </DropdownDefault>
            </div>
          :
            <ul id="nav-mobile" className="right">
              <li className={styles.item}>
                <Link to={`/signin`}>เข้าสู่ระบบ</Link>
              </li>
              <li className={styles.item}>
                <Link to={`/signup`}>สมัครสมาชิก</Link>
              </li>
            </ul>
          }

        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Header)
