// Containers
import RubberReceivablesContainer from 'client/containers/dashboard/SystemRubber/RubberReceivables/containers/RubberReceivablesContainer'

// Routes
import RubberReceivablesMenuRoutes from './routes/RubberReceivablesMenuRoutes'



const RubberReceivablesRoutes = [
  {
    path: '/:username/rubber-receivables',
    component: RubberReceivablesContainer,
    routes: [
      ...RubberReceivablesMenuRoutes,
    ]
  }
]

export default RubberReceivablesRoutes
