import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'

// Utils
import getFormValue from 'utils/getFormValue'

// components
import { Box, ButtonLink, Title, Row, ButtonClose, Column, Or } from 'components/UserInterface'
import ModalDefault from 'components/modal/ModalDefault'
import FormValidation from 'components/FormValidation'
import BtnSubmit from 'components/button/BtnSubmit'

// Component Global
import { Email, Password, SocialSignInAll } from './components/AuthForms'

// Import Actions
import { signIn } from 'core/redux/Auth/AuthActions'


class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit(e, formName) {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    let redirectAfterLogin = this.props.location.search.split("&")[0].replace("?", "").split("next=")[1]
    let url = redirectAfterLogin ? redirectAfterLogin : '/'

    if (params) {
      this.props.dispatch(signIn({
        body: params,
        loadId: formName,
        errorAlert: true,
        props: this.props,
        redirect: { url: url },
        toastMsg: 'เข้าสู่ระบบเรียบร้อยแล้ว'
      }))
    }
  }

  render() {
    return (
      <div>
        <Helmet title="เข้าสู่ระบบ" />
        <ModalDefault showOnly={true} bgOverlay="#7cb342" width="350px">
          <Box padding="30px 30px 0 30px" background="#fff">
            <ButtonClose to={`/`} />
            <Title align="center" title="เข้าสู่ระบบ" />

            <SocialSignInAll {...this.props} />

            <Or text="เข้าสู่ระบบด้วยอีเมล" />

            <FormValidation ref="signin" name="signin" submit={(e) => this.handleSubmit(e, 'signin')} >
              <Email />
              <Password />

              <Row>
                <Column col="column col-xxs-12 mg-top-10" align="center-xs text-right-sm text-right-md text-right-lg text-right-xlg">
                  <BtnSubmit
                    loadingId="signin"
                    icon="lock_open"
                    iconAlign="left"
                    text="เข้าสู่ระบบ"
                    className="waves-effect waves-light btn light-green darken-1 width-full-xs"
                    btnDisabled
                  />
                </Column>
              </Row>
            </FormValidation>

            <Row align="center">
              <div style={{ borderTop: '1px solid #eee', margin: '20px -30px 0 -30px' }}>
                <div style={{ width: '50%', float: 'left' }}>
                    <Link to="forgot-password" style={{ lineHeight: '35px' }}>ลืมรหัสผ่าน</Link>
                </div>
                <div style={{ borderLeft: '1px solid #eee', width: '50%', float: 'left' }}>
                  <Link to="signup" style={{ lineHeight: '40px' }}>สมัครสมาชิก</Link>
                </div>
              </div>
            </Row>
          </Box>
        </ModalDefault>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    auth: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(SignIn))
