import React, { Component } from 'react'

// Components Global
import InputNumber from 'components/Inputs/InputNumber'
import InputDisable from 'components/Inputs/InputDisable'
import Textarea from 'components/Inputs/Textarea'
import TextInput from 'components/input/TextInput'
import InputPhoneNumber from 'components/input/InputPhoneNumber'
import Select from 'components/Inputs/Select'
import Switch from 'components/Inputs/Switch'


export const BillShortPageSizeWidth = (props) => {
  return (
    <InputNumber
      label="ความกว้างบิล"
      id="billShort.pageSizeCustom.width"
      value={props.value  || '300'}
      placeholder="ความกว้างบิล"
      required
      iconValid />
  )
}

export const BillShortPageSizeHeight = (props) => {
  return (
    <InputDisable
      label="ความยาวบิล"
      id="height-disable"
      value={props.value || 'auto'}
      placeholder="ความยาวบิล"
    />
  )
}

export const BillShortTextFooter = (props) => {
  const { lang, value } = props

  return (
    <Textarea
      label="ข้อความท้ายบิล"
      placeholder="ข้อความท้ายบิล"
      id={`billShort.textFooter.${lang}`}
      value={value[lang] || ''}
      iconValid
    />
  )
}

export const Note = (props) => {
  let { id, lang, label, value } = props
  lang = lang || 'local'

  console.log(value)

  return (
    <Textarea
      label={label}
      placeholder={label}
      id={`${id}.${lang}`}
      value={value && value[lang] || ''}
    />
  )
}

export const Vat = (props) => {
  return (
    <Select
      active
      id="vat"
      label="ประเภทบริษัท"
      placeholder="ประเภทบริษัท"
      placeholderDisabled
      value={props.value}
      options={[
        { value: 'false', name: 'บริษัท/หจก - ยังไม่เข้าระบบภาษีมูลค่าเพิ่ม (ไม่คิดภาษีมูลค่าเพิ่ม)' },
        { value: 'true', name: 'บริษัท/หจก - จดภาษีมูลค่าเพิ่มแล้ว (คิดภาษีมูลค่าเพิ่ม)' },
      ]}
    />
  )
}

export const BusinessUsername = (props) => {
  return (
    <TextInput
      label="ไอดี"
      id="username"
      value={props.value}
      active
      placeholder="ไอดี"
      inputType="text"
      type="text"
      required="true"
      iconValid />
  )
}

export const BusinessName = (props) => {
  const lang = props.lang || 'local'
  const id = `name.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ชื่อธุรกิจ"
      placeholder="ชื่อธุรกิจ"
      id={id}
      value={value}
      active
      inputType="text"
      type="text"
      required="true"
      iconValid
    />
  )
}

export const BusinessType = (props) => {
  return (
    <Select
      active
      required
      id="type"
      label="ประเภทธุรกิจ"
      placeholder="ประเภทธุรกิจ"
      placeholderDisabled
      value={props.value}
      options={[
        { value: 'store', name: 'ร้านค้า' },
        { value: 'tour', name: 'บริษัททัวร์' },
        { value: 'hotel', name: 'โรงแรมที่พัก' },
        { value: 'restaurant', name: 'ร้านอาหาร' },
        { value: 'service', name: 'ธุรกิจบริการ' },
        { value: 'rubber', name: 'ยางพารา' },
      ]}
    />
  )
}

export const BusinessCompanyName = (props) => {
  const lang = props.lang || 'local'
  const id = `companyName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ชื่อบริษัท"
      id={id}
      value={value}
      active
      placeholder="ชื่อบริษัท"
      inputType="text"
      type="text"
      required="true"
      iconValid />
  )
}

export const BusinessAddress = (props) => {
  const lang = props.lang || 'local'
  const id = `address.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <Textarea
      label="ที่อยู่"
      placeholder="ที่อยู่"
      required
      id={id}
      value={value}
      iconValid />
  )
}


export const TaxId = (props) => {
  return (
    <TextInput
      label="เลขประจำตัวผู้เสียภาษี"
      id="taxId"
      value={props.value}
      active
      placeholder="เลขประจำตัวผู้เสียภาษี"
      inputType="text"
      type="text-min-max"
      min="13" max="13"
      msgError="ต้องมีให้ครบ 13 หลัก"
      required="true"
      iconValid />
  )
}

export const BusinessBranchName = (props) => {
  const lang = props.lang || 'local'
  const id = `branchName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="สำนักงาน/สาขาเลขที่"
      id={id}
      value={value}
      active
      placeholder="สำนักงาน/สาขาเลขที่"
      inputType="text"
      type="text"
      iconValid />
  )
}

export const BusinessPhone = (props) => {
  return (
    <InputPhoneNumber
      label="เบอร์มือถือสำหรับติดต่อกลับและรับ SMS"
      id="phone"
      required
      value={props.value}
      iconValid />
  )
}

export const BusinessTel = (props) => {
  return (
    <InputPhoneNumber
      label="โทรศัพท์"
      id="tel"
      value={props.value}
      iconValid />
  )
}

export const BusinessMobile = (props) => {
  return (
    <InputPhoneNumber
      label="มือถือ"
      id="mobile"
      value={props.value}
      iconValid />
  )
}

export const BusinessFax = (props) => {
  return (
    <TextInput
      label="แฟกซ์"
      id="fax"
      value={props.value}
      active
      placeholder="เบอร์แฟกซ์"
      inputType="text"
      type="text"
      iconValid />
  )
}

export const BusinessEmail = (props) => {
  return (
    <TextInput
      label="อีเมล"
      id="email"
      value={props.value}
      active
      placeholder="อีเมล"
      inputType="text"
      type="text"
      iconValid />
  )
}

export const BusinessLine = (props) => {
  return (
    <TextInput
      label="ไลน์"
      id="lineId"
      value={props.value}
      active
      placeholder="อีเมล"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const BusinessFacebook = (props) => {
  return (
    <TextInput
      label="เฟซบุ๊ต"
      id="facebook"
      value={props.value}
      active
      placeholder="อีเมล"
      inputType="text"
      type="text"
      iconValid
    />
  )
}

export const BusinessWebsite = (props) => {
  return (
    <TextInput
      label="เว็บไซต์"
      id="website"
      value={props.value}
      active
      placeholder="เว็บไซต์"
      inputType="text"
      type="text"
      iconValid />
  )
}

export const PaymentStatus = (props) => {
  const { id } = props

  return (
    <div className="border-eee border-radius-5 pd-10 pd-left-15">
      <Switch
        id={`paymentMethods.${id}.status`}
        value={props.value}
        label="สถานะ"
      />
    </div>
  )
}

export const BusinessToken = (props) => {
  return (
    <TextInput
      label={props.label}
      id={props.id}
      value=""
      active
      placeholder={props.placeholder}
      inputType="text"
      type="text"
      iconValid
    />
  )
}
