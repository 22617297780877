import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import SalesMenuContainer from 'client/containers/dashboard/Sales/containers/SalesMenuContainer'
import OrdersListContainer from 'client/containers/dashboard/Sales/containers/OrdersListContainer'

import PosOnlineRoutes from './PosOnlineRoutes'

// Pages
const OrderManagePage = lazy(() => import('client/containers/dashboard/Sales/pages/OrderManagePage'))
const OrderDraftsListPage = lazy(() => import('client/containers/dashboard/Sales/pages/OrderDraftsListPage'))
const OrdersExportPages = lazy(() => import('client/containers/dashboard/Sales/pages/OrdersExportPages'))


// Pages NotFound
import NotFound from 'client/common/Pages/NotFound'

const status = ':status(all|contacts|unpaid|waiting-confirmed-paid|prepare-shipping|successed|refunded|canceled)'

import TestPage from 'client/containers/dashboard/Sales/pages/TestPage'

const SalesMenuRoutes = [
  {
    path: '/:username/sales',
    component: SalesMenuContainer,
    routes: [
      {
        path: `/:username/sales/orders/${status}/:orders_id`,
        component: suspenseComponent(OrdersListContainer),
        params: { forUser: false },
        routes: [
          {
            path: `/:username/sales/orders/${status}/:orders_id`,
            exact: true,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(OrderManagePage)
          }
        ]
      },
      {
        path: `/:username/sales/export`,
        component: suspenseComponent(OrdersExportPages),
      },
      {
        path: '/:username/sales',
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'draft' },
        component: suspenseComponent(OrderDraftsListPage),
        routes: PosOnlineRoutes,
      },
      {
        component: NotFound
      }
    ]
  }
]

export default SalesMenuRoutes
