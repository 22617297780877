import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import SiteUpdateContainer from 'client/containers/dashboard/Sites/containers/SiteUpdateContainer'

// Components Pages
const SitePage = lazy(() => import('client/containers/dashboard/Sites/pages/SitePage'))
const SiteDomianPage = lazy(() => import('client/containers/dashboard/Sites/pages/SiteDomianPage'))
const SiteAnalyticsPage = lazy(() => import('client/containers/dashboard/Sites/pages/SiteAnalyticsPage'))
const SitePhotosPage = lazy(() => import('client/containers/dashboard/Sites/pages/SitePhotosPage'))
const SitePlatformsPage = lazy(() => import('client/containers/dashboard/Sites/pages/SitePlatformsPage'))



const SiteUpdateRoutes = [
  {
    path: '/:username/sites/:sites_id/update',
    component: SiteUpdateContainer,
    routes: [
      {
        path: '/:username/sites/:sites_id/update',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SitePage)
      },
      {
        path: '/:username/sites/:sites_id/update/domain',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SiteDomianPage)
      },
      {
        path: '/:username/sites/:sites_id/update/analytics',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SiteAnalyticsPage)
      },
      {
        path: '/:username/sites/:sites_id/update/platforms',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SitePlatformsPage)
      },
      {
        path: '/:username/sites/:sites_id/update/photos',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SitePhotosPage)
      }
    ]
  }
]

export default SiteUpdateRoutes


