// Containers
import PlacesMenuContainer from 'client/containers/dashboard/Places/containers/PlacesMenuContainer'

// Components
import PlacesListPage from 'client/containers/dashboard/Places/pages/PlacesListPage'


const PlacesMenuRoutes = [
  {
    path: '/:username/places',
    component: PlacesMenuContainer,
    routes: [
      {
        path: '/:username/places',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'สถานที่', icon: 'assignment_late' },
        component: PlacesListPage
      },
      {
        path: '/:username/places/open',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'open', title: 'สถานที่', icon: 'assignment_late' },
        component: PlacesListPage
      },
      {
        path: '/:username/places/close',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'close', title: 'สถานที่', icon: 'assignment_late' },
        component: PlacesListPage
      },
      {
        path: '/:username/places/trash',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        params: { status: 'trash', title: 'บริการ', icon: 'assignment_late' },
        component: PlacesListPage
      },
    ]
  }
]

export default PlacesMenuRoutes
