import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
const AttributesUpdateContainer = lazy(() => import('client/containers/dashboard/Attributes/containers/AttributesUpdateContainer'))

// Components Pages
const DescriptionsPage = lazy(() => import('client/containers/dashboard/Attributes/pages/AttributeUpdate/DescriptionsPage'))
const AttributePhotosPage = lazy(() => import('client/containers/dashboard/Attributes/pages/AttributePhotosPage'))
const ItemsListPage = lazy(() => import('client/containers/dashboard/Attributes/pages/AttributeUpdate/ItemsListPage'))
const NotificationsListPage = lazy(() => import('client/containers/dashboard/Attributes/pages/AttributeUpdate/NotificationsListPage'))


const AttributeTypeUpdateRoutes = (typePath) => [
  {
    path: `/:username/${typePath}/attributes/:attributes_id/update`,
    component: suspenseComponent(AttributesUpdateContainer),
    routes: [
      {
        path: `/:username/${typePath}/attributes/:attributes_id/update`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(DescriptionsPage)
      },
      {
        path: `/:username/${typePath}/attributes/:attributes_id/update/photos`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(AttributePhotosPage)
      },
      {
        path: `/:username/${typePath}/attributes/:attributes_id/update/items`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(ItemsListPage)
      },
      {
        path: `/:username/${typePath}/attributes/:attributes_id/update/notifications/:typeNotification(email|facebook|line)`,
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(NotificationsListPage)
      }
    ]
  }
]

export default AttributeTypeUpdateRoutes
