import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import SettingsContainer from 'client/containers/dashboard/Settings/containers/SettingsContainer'

// Routes
import SettingsMenuRoutes from './routes/SettingsMenuRoutes'


const SettingsRoutes = [
  {
    path: '/:username/settings',
    component: SettingsContainer,
    routes: [
      ...SettingsMenuRoutes
    ]
  }
]

export default SettingsRoutes
