// Containers
import RubberReceiptsContainer from 'client/containers/dashboard/SystemRubber/RubberReceipts/containers/RubberReceiptsContainer'

// Routes
import RubberReceiptsMenuRoutes from './routes/RubberReceiptsMenuRoutes'



const RubberSalesRoutes = [
  {
    path: '/:username/rubber-receipts',
    component: RubberReceiptsContainer,
    routes: [
      ...RubberReceiptsMenuRoutes,
    ]
  }
]

export default RubberSalesRoutes
