import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberCustomersMenuContainer from 'client/containers/dashboard/SystemRubber/RubberCustomerSellers/containers/RubberCustomersMenuContainer'

// Components
const RubberCustomersListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberCustomerSellers/containers/RubberCustomersListContainer'))
const RubberCustomerManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberCustomerSellers/pages/RubberCustomerManagePage'))


const RubberCustomerSellersMenuRoutes = [
  {
    path: '/:username/rubber-customer-sellers',
    component: RubberCustomersMenuContainer,
    routes: [
      {
        path: '/:username/rubber-customer-sellers/:status(trash)/:customers_id',
        component: suspenseComponent(RubberCustomersListContainer),
        routes: [
          {
            path: '/:username/rubber-customer-sellers/:status(trash)/:customers_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberCustomerManagePage),
          }
        ]
      },
      {
        path: '/:username/rubber-customer-sellers/:customers_id',
        component: suspenseComponent(RubberCustomersListContainer),
        routes: [
          {
            path: '/:username/rubber-customer-sellers/:customers_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberCustomerManagePage),
          }
        ]
      },
    ]
  }
]

export default RubberCustomerSellersMenuRoutes
