// Containers
import CustomerAffiliatesContainer from 'client/containers/dashboard/CustomerAffiliates/containers/CustomerAffiliatesContainer'
//import CustomerAffiliateContainer from 'client/containers/dashboard/CustomerAffiliates/containers/CustomerAffiliateContainer'

// Routes
import CustomerAffiliatesMenuRoutes from './routes/CustomerAffiliatesMenuRoutes'
//import CustomerAffiliateUpdateRoutes from './routes/CustomerAffiliateUpdateRoutes'


const CustomerAffiliatesRoutes = [
  {
    path: '/:username/customer-affiliates',
    component: CustomerAffiliatesContainer,
    routes: [
      // {
      //   path: '/:username/customer-affiliates/advanced/:customers_id',
      //   component: CustomerAffiliateContainer,
      //   auth: { reqAuthBiz: true, reqExpDate: true },
      //   routes: [
      //     ...CustomerAffiliateUpdateRoutes
      //   ]
      // },
      ...CustomerAffiliatesMenuRoutes
    ]
  }
]

export default CustomerAffiliatesRoutes
