import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import SellOnlineMenuContainer from 'client/containers/dashboard/SellOnline/containers/SellOnlineMenuContainer'
import OrdersListContainer from 'client/containers/dashboard/SellOnline/containers/OrdersListContainer'

// Pages
const SellOnlineManagePage = lazy(() => import('client/containers/dashboard/SellOnline/pages/SellOnlineManagePage'))
const OrdersExportPages = lazy(() => import('client/containers/dashboard/SellOnline/pages/OrdersExportPages'))
const OrdersReportPage = lazy(() => import('client/containers/dashboard/Reports/pages/OrdersReportPage'))


// Pages NotFound
import NotFound from 'client/common/Pages/NotFound'

const status = ':status(all|draft|unpaid|prepare|successed|refunded|canceled)'


const SalesMenuRoutes = [
  {
    path: '/:username/sell-online',
    component: SellOnlineMenuContainer,
    routes: [
      {
        path: `/:username/sell-online/${status}/:orders_id`,
        component: suspenseComponent(OrdersListContainer),
        params: { forUser: false },
        routes: [
          {
            path: `/:username/sell-online/${status}/:orders_id`,
            exact: true,
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(SellOnlineManagePage)
          }
        ]
      },
      {
        path: `/:username/sell-online/export`,
        component: suspenseComponent(OrdersExportPages),
      },
      {
        path: `/:username/sell-online/reports`,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(OrdersReportPage)
      },
      {
        component: NotFound
      }
    ]
  }
]

export default SalesMenuRoutes
