// Containers
import RubberCustomersContainer from 'client/containers/dashboard/SystemRubber/RubberCustomerSellers/containers/RubberCustomersContainer'


// Routes
import RubberCustomerSellersMenuRoutes from './routes/RubberCustomerSellersMenuRoutes'


const RubberCustomerSellersRoutes = [
  {
    path: '/:username/rubber-customer-sellers',
    component: RubberCustomersContainer,
    routes: [
      ...RubberCustomerSellersMenuRoutes
    ]
  }
]

export default RubberCustomerSellersRoutes
