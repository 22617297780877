// Containers
import ArticlesContainer from 'client/containers/dashboard/Articles/containers/ArticlesContainer'

// Routes
import ArticlesMenuRoutes from './routes/ArticlesMenuRoutes'

// Routes Folders
import FolderUpdateRoutes from '../FoldersRoutes/routes/FolderUpdateRoutes'


const ArticlesRoutes = [
  {
    path: '/:username/articles',
    component: ArticlesContainer,
    routes: [
      ...FolderUpdateRoutes({ path: '/:username/articles', typePath: 'articles' }),
      ...ArticlesMenuRoutes({ path: '/:username/articles' }),
    ]
  },
  {
    path: '/:username/articles-attr/:attributes_path',
    component: ArticlesContainer,
    routes: [
      ...FolderUpdateRoutes({ path: '/:username/articles-attr/:attributes_path', typePath: 'articles' }),
      ...ArticlesMenuRoutes({ path: '/:username/articles-attr/:attributes_path' }),
    ]
  },
]

export default ArticlesRoutes
