import { lazy } from 'react'
import suspenseComponent from 'client/util/suspenseComponent'

// Containers
import RubberPurchasesMenuContainer from 'client/containers/dashboard/SystemRubber/RubberPurchases/containers/RubberPurchasesMenuContainer'

// Components
const PurchasesListContainer = lazy(() => import('client/containers/dashboard/SystemRubber/RubberPurchases/containers/RubberPurchasesListContainer'))
const RubberPurchaseManagePage = lazy(() => import('client/containers/dashboard/SystemRubber/RubberPurchases/pages/RubberPurchaseManagePage'))

const RubberPurchasesMenuRoutes = [
  {
    path: '/:username/rubber-purchases',
    component: RubberPurchasesMenuContainer,
    routes: [
      {
        path: '/:username/rubber-purchases/:reserve(all|reserve)/:reserveStatus(all|estimate|finish)/:purchases_id',
        component: suspenseComponent(PurchasesListContainer),
        routes: [
          {
            path: '/:username/rubber-purchases/:reserve(all|reserve)/:reserveStatus(all|estimate|finish)/:purchases_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberPurchaseManagePage)
          },
        ]
      },
    ]
  }
  /*{
    path: '/:username/rubber-purchases',
    component: RubberPurchasesMenuContainer,
    routes: [
      {
        path: '/:username/rubber-purchases/:purchases_id',
        component: suspenseComponent(PurchasesListContainer),
        routes: [
          {
            path: '/:username/rubber-purchases/:purchases_id',
            auth: { reqAuthBiz: true, reqExpDate: true },
            component: suspenseComponent(RubberPurchaseManagePage)
          },
        ]
      },
    ]
  }*/
]

export default RubberPurchasesMenuRoutes
