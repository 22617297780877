// routes
import BusinessesRoutes from './BusinessesRoutes'

import AppStoreContainer from 'store/AppStoreContainer'
import BusinessesList from 'store/modules/Businesses/pages/BusinessesList'
import SignIn from 'store/modules/Auth/pages/SignIn'
import SignUp from 'store/modules/Auth/pages/SignUp'
import SignOut from 'store/modules/Auth/pages/SignOut'
import ConfirmAccount from 'store/modules/Auth/pages/ConfirmAccount'
import ConfirmAccountToken from 'store/modules/Auth/pages/ConfirmAccountToken'
import ForgotPassword from 'store/modules/Auth/pages/ForgotPassword'
import ResetPassword from 'store/modules/Auth/pages/ResetPassword'


const StoreRoutes = [
  {
    component: AppStoreContainer,
    routes: [
      {
        path: '/',
        exact: true,
        auth: { reqAuth: true },
        component: BusinessesList
      },
      {
        path: '/signin',
        auth: { unAuth: true },
        component: SignIn
      },
      {
        path: '/signup',
        auth: { unAuth: true },
        component: SignUp
      },
      {
        path: '/signout',
        component: SignOut
      },
      {
        path: '/confirm-account',
        auth: { reqAuth: true, active: false },
        component: ConfirmAccount
      },
      {
        path: '/confirm-account-token/:token',
        component: ConfirmAccountToken
      },
      {
        path: '/forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword
      },
      ...BusinessesRoutes
    ]
  }
]

export default StoreRoutes
