// Containers
import RubberCustomersContainer from 'client/containers/dashboard/SystemRubber/RubberCustomerBuyers/containers/RubberCustomersContainer'


// Routes
import RubberCustomerBuyersMenuRoutes from './routes/RubberCustomerBuyersMenuRoutes'


const RubberCustomerBuyersRoutes = [
  {
    path: '/:username/rubber-customer-buyers',
    component: RubberCustomersContainer,
    routes: [
      ...RubberCustomerBuyersMenuRoutes
    ]
  }
]

export default RubberCustomerBuyersRoutes
